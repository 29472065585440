@calendar_toolbar_fontsize: 2.5rem;
@calendar_toolbar_fontsize_xsmall: 1.6rem;
@calendar_toolbar_fontfamily: 'Roboto', @sans-serif-font-family;
@calendar_toolbar_marginbottom: 0.8em;

@calendar_head_bg: #125d5d;
@calendar_head_color: @white;

@calendar_btn_fontsize: 1rem;
@calendar_btn_color: @white;
@calendar_btn_color_hover: @white;
@calendar_btn_color_disabled: tint(@body-color,50%);
@calendar_btn_bg: #125d5d;
@calendar_btn_bg_hover: darken(#125d5d,10%);
@calendar_btn_bg_disabled: @light-grey;
@calendar_btn_border: 1px solid #f6f6f6;
@calendar_btn_border_hover: 1px solid #f6f6f6;
@calendar_btn_border_disabled: 1px solid @light-grey;

@calendar_holiday_border: 1px solid #125d5d;
@calendar_holiday_color: #125d5d;

.fc-view{
  background-color: @white;
}

.fc-timeGrid-view .fc-day-grid .fc-row{
  min-height: 1.5em;
}
.fc-toolbar{
  &.fc-header-toolbar{
    margin-bottom: @calendar_toolbar_marginbottom;
  }
  h2{
    display: inline-block;
    font-size: @calendar_toolbar_fontsize;
    font-family: @calendar_toolbar_fontfamily;
    vertical-align: middle;
    font-weight: @bold-font-weight;
    @media @xsmall {
      font-size: @calendar_toolbar_fontsize_xsmall;
    }
  }
}
.fc-button{
  font-size: @calendar_btn_fontsize;
  height: auto;
}
.fc-button-primary{
  background-color: @calendar_btn_bg;
  border: @calendar_btn_border;
  color: @calendar_btn_color;
  &:disabled{
    color: @calendar_btn_color_disabled;
    background: @calendar_btn_bg_disabled;
    border: @calendar_btn_border_disabled;
  }
  &:not(:disabled):hover,&:not(:disabled):focus{
    color: @calendar_btn_color_hover;
    background-color: @calendar_btn_bg_hover;
    border: @calendar_btn_border_hover;
  }
}
.fc-axis{
  font-size: 1.2rem;
  background-color: #f6f6f6;
}
.fc table {
  margin: 0;
  th{
    background-color: @calendar_head_bg;
    color: @calendar_head_color;
    font-size: 1.2rem;
  }
  .holiday{
    background-color: transparent;
    border: @calendar_holiday_border;
    color: @calendar_holiday_color;
    pointer-events: none;
  }
}
