nav#gmenu.is-default {
  display: none
}

.p-header {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;

  @media @medium-up {
    padding-left: 5rem;
    padding-right: 3rem;
    position: relative;
  }

  @media @small {
    padding-left: 2rem;
    padding-right: 0rem;
  }

  @media @xsmall {
    justify-content: center;
  }

  &__logo {
    .hoverOpacity;
    margin-right: 2rem;

    img {
      display: block;
      max-width: 60vw;

    }
  }

  // rwd_001/headerからコピーして編集
  >.drawerNav-btn {
    position: absolute;
    top: 50%;
    right: 7px;
    transform: translateY(-50%);
    border-width: 0;
    padding: 0.25em;
    font-size: 2.8rem;
    height: auto;
    background-color: transparent;
    transition: all @transition-fast ease-in-out;
    z-index: 0;

    @media @small-up {
      display: none;
    }
  }
}

// rwd_001/headerからコピーして編集
.p-gnav {
  display: flex;
  justify-content: center;
  overflow: auto;

  &.is-open {
    @media @xsmall {
      transform: translateX(0);
    }
  }

  &__list {
    @media @small-up {
      display: flex;
    }

    @media @xsmall {
      flex-direction: column;
      justify-content: flex-start;
      margin: 0 0 2rem;
      padding: 0;
      width: 100%;
    }
  }

  &__inner {
    @media @small-up {
      display: flex;
      align-items: center;
    }
  }

  &__item {
    margin: 0;

    >a {
      display: block;
      color: @body-color;
      font-weight: @bold-font-weight;
      letter-spacing: 0.1em;
      transition: all @transition-fast ease-in-out;

      &:hover,
      &:focus {
        text-decoration: none;
      }
    }

    @media @small-up {
      text-align: center;

      >a {
        display: flex;
        align-items: center;
        justify-content: center;
        height: 100px;
        padding: 0.5em 1em;

        &:hover,
        &:focus,
        &.current {
          color: @primary;
        }
      }

      &.is-form a {
        background-color: @secondary;
        color: @white;
        padding: 0.5em 2em;

        &:hover,
        &:focus {
          background-color: tint(@secondary, 30%);
        }
      }
    }

    @media @xsmall {
      position: relative;
      flex-grow: 0;
      border-width: 0;
      padding: 0;
      border: solid tint(@black, 90%);
      border-width: 1px 0;

      >a {
        padding: 1em;
        padding-left: 1.6em;
      }

      >a:after {
        content: '';
        display: block;
        position: absolute;
        transition: width @transition-medium ease-in-out;
        content: "\f105";
        font-family: fontAwesome;
        width: 1.6em;
        top: 1em;
        left: 0;
        font-weight: @medium-font-weight;
        text-align: center;
      }

      &.is-form {
        display: none;
      }

      &+li {
        border-top-width: 0;
      }
    }

    &__indicator {
      @media @small-up {
        display: none;
      }

      @media @xsmall {
        position: absolute;
        top: 0;
        right: 0;
        padding: 0;
        width: 50px;
        height: 50px;
        z-index: 10;
        border-width: 0 !important;
        background-color: transparent !important;
      }

      &:before,
      &:after {
        position: absolute;
        top: 50%;
        left: 50%;
        content: '';
        width: 10px;
        height: 1px;
        background-color: @primary;
        transition: transform @transition-fast ease-in-out;
      }

      &:after {
        transform: rotate(-90deg);
      }

      &.is-active {
        &:before {
          transform: rotate(180deg);
        }

        &:after {
          transform: rotate(0deg);
        }
      }
    }

    &__item:hover &__subnav,
    &__item.current &__subnav {
      opacity: 1;
      transform: scale(1);
      z-index: 10;
    }

  }

  &__tel {
    font-family: 'Roboto', @sans-serif-font-family;
    font-weight: @medium-font-weight;
    font-size: 2.1rem;
    letter-spacing: 0.075em;
    color: @primary;
    transition: all @transition-fast ease-in-out;

    &:not(:disabled):hover,
    &:not(:disabled):focus {
      color: tint(@primary, 15%);
    }

    @media @xsmall {
      padding-left: 0;
      padding-right: 0;
    }
  }

  &__subnav {
    @media @small-up {
      display: block !important;
      position: absolute;
      top: 100%;
      left: 0;
      width: 100%;
      transform: scale(1, 0.01);
      opacity: 0;
      transform-origin: center 0;
      color: white;
      background-color: fade(#009245, 90%);
      z-index: 1;
      transition: all 0.2s 1s ease-in-out;
    }

    @media @xsmall {
      margin-top: -0.4em;
    }

    &-list {
      @media @small-up {
        display: flex;
        justify-content: center;
        align-items: stretch;
        width: 100%;
        max-width: 960px;
        height: 50px;
        margin: 0 auto;
        padding: .5rem 0;

        >a {

          &:hover,
          &:focus,
          &.current {
            opacity: 1;
          }

          span {
            position: relative;

            &:after {
              opacity: 0;
              content: "";
              position: absolute;
              left: 0;
              right: 0;
              bottom: 2px;
              margin: auto;
            }
          }
        }

        >a {
          display: flex;
          align-items: center;
          justify-content: center;
          height: 45px;
          padding: 5px 21px 10px;
          border-width: 0 1px;
          font-family: sans-serif;
          color: inherit;
          text-align: center;
          font-size: 1.3rem;
          letter-spacing: 0.15em;
          text-indent: 0.2em;
          z-index: 1;
          transition: all .1s ease-in-out;

          @media @small-up {
            font-weight: @medium-font-weight;
          }

          &:not(:first-child) {
            span:before {
              content: "";
              position: absolute;
              top: 0;
              bottom: 0;
              left: -21px;
              margin: auto;
              width: 1px;
              height: 13px;
              background-color: rgba(255, 255, 255, .5);
            }
          }

          span {
            position: relative;

            &:after {
              opacity: 0;
              content: "";
              position: absolute;
              left: 0;
              right: 0;
              bottom: -2px;
              margin: auto;
              width: 100%;
              height: 1px;
              background-color: rgba(255, 255, 255, .6);
            }
          }

          &:hover,
          &:focus,
          &.current {
            text-decoration: none;
            color: @white;

            span:after {
              opacity: 1;
            }
          }
        }

        a+a {
          border-left-width: 0;
        }
      }
    }
  }

  &__subnav-list {
    @media @xsmall {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      margin-bottom: 1.3em;

      >a {
        display: block;
        padding: 0.4em 0.4em 0.4em 2.5em;
        font-feature-settings: "palt";
        letter-spacing: .1em;
        color: @dark;
      }
    }
  }

  &__list:hover a.current+&__subnav {
    @media @small-up {
      opacity: 0;
    }
  }

  &__list a.current:hover+&__subnav,
  &__list a.current+&__subnav:hover {
    opacity: 1;
  }

  &__item:hover &__subnav,
  &__item:focus &__subnav {
    opacity: 1;
    transform: scale(1);
    z-index: 2;
  }

  &__item:hover &__subnav {
    @media @small-up {
      transition: all 0.1s ease-in-out;
    }
  }

  &__item:focus &__subnav {
    transition: all 0.2s 0.3s ease-in-out !important;
  }


  // プライバシーポリシーやサイトマップ（SPのみ表示）
  &__sublist {
    margin: 1.5rem -0.6em;
    white-space: nowrap;
  }

  &__subitem {
    position: relative;
    display: inline-block;
    padding: 0.2em 0.6em;
    margin: 0;
    font-size: 1.2rem;

    &:before {
      position: absolute;
      top: 50%;
      left: 0;
      transform: translate(-50%, -50%);
      content: '';
      height: 1em;
      width: 1px;
      background-color: tint(@body-color, 30%);
      display: none;
    }

    &+li:before {
      display: block;
    }

    a {
      color: tint(@body-color, 30%);
    }
  }

  &__copyright {
    color: tint(@body-color, 30%);
    font-size: 1.2rem;
  }
}

.p-gnav {
  @media @xsmall {
    justify-content: flex-start;
    position: fixed;
    top: 0;
    left: 100%;
    width: ~"calc( 100vw - 54px )";
    height: 100%;
    transition: all @transition-fast ease-in-out;
    background-color: @white;
    box-shadow: 0 0 6px fade(@black, 20%);
    z-index: 100;
    background-color: @white;
    opacity: 0;
  }

  &.is-open {
    @media @xsmall {
      opacity: 1;
      transform: translateX(-100%);
    }
  }

  &__inner {
    @media @xsmall {
      position: relative;
      height: 100%;
      width: 100%;
      padding: 60px 22px 22px;
      overflow: auto;
      -webkit-overflow-scrolling: touch;
    }
  }

  &__button-inquiry {
    position: relative;
    height: 5.5rem;
    font-size: 1.4rem;
    align-items: center;
    justify-content: center;

    &:after {
      position: absolute;
      content: "\f105";
      font-family: fontAwesome;
      width: 1.6em;
      top: calc(50% - 0.6em);
      right: 0;
      font-weight: @medium-font-weight;
    }

    @media @small-up {
      display: none;
    }

    @media @xsmall {
      display: flex;
    }

    &:hover,
    &:focus {
      text-decoration: none;
    }
  }

  .drawerNav-btn.is-close {
    position: absolute;
    top: 0;
    right: 0;
    border-width: 0;
    width: 60px;
    height: 60px;
    padding: 10px;
    z-index: 1;
    background-color: fade(@white, 50%);
    transform: translateY(0);

    &:hover,
    &:focus {
      border-width: 0;
    }

    @media @small-up {
      display: none;
    }

    span {
      position: absolute;
      top: 50%;
      left: 50%;
      height: 2px;
      width: 20px;
      background-color: @body-color;

      &:nth-child(1) {
        transform: translate(-50%, -50%) rotate(45deg);
      }

      &:nth-child(2) {
        transform: translate(-50%, -50%) rotate(-45deg);
      }
    }
  }

}

.mainImg {
  & &Inner {
    position: relative;
    color: inherit;
    background: radial-gradient(circle at 80% 40%, #f4ed3a 4%, #83c434 20%);
    height: 192px;
    padding-bottom: 67px;

    @media @xsmall {
      height: auto;
      padding-bottom: 20px;
      background: radial-gradient(circle at 80% 40%, #f4ed3a 4%, #83c434 40%);
    }

    &:after {
      @arc: escape('<svg xmlns="http://www.w3.org/2000/svg" preserveAspectRatio="none" viewBox="0 0 1400 67"><path fill="@{white}" d="M1400,67C1269.48,27.21,1005,0,700,0S130.52,27.21,0,67Z"/></svg>');
      content: '';
      position: absolute;
      bottom: 0;
      left: 0;
      width: 100%;
      height: 67px;
      max-height: 100%;
      background: center bottom / 100% 100% no-repeat;
      background-image: url('data:image/svg+xml;charset=UTF-8,@{arc}');

      @media @xsmall {
        height: 20px;
      }
    }
  }

  h2 {
    display: flex;
    flex-direction: column;
    align-items: center;
    color: @white;
    font-size: 4rem;
    letter-spacing: 0.11em;
    text-indent: 0.11em;
    font-family: 'Play', @sans-serif-font-family;
    font-weight: @bold-font-weight;
    padding: 0.7em 0;

    @media @xsmall {
      font-size: 2.6rem;
    }

    small {
      color: inherit;
      font-size: 1.4rem;
      letter-spacing: 0.29em;
      text-indent: 0.29em;
      font-weight: inherit;

      @media @xsmall {
        font-size: 1.3rem;
      }
    }

    &:last-child {
      margin-bottom: 0;
    }
  }
}