.is--facility {
  // 1カラム化
  .hybs-one-column(100%);

  .breadcrumbs,
  .detailfooter {
    display: none;
  }
}

.facility-head-nav {
  display: flex;
  align-items: stretch;
  justify-content: center;
  margin-bottom: 80px;
  padding-left: 27px;
  padding-right: 27px;

  @media @xsmall {
    margin-bottom: 50px;
  }

  .button {
    margin: 0 7px 10px;
    font-size: 1.2rem;
    font-weight: @bold-font-weight;
    height: auto;

    @media @small-up {
      min-width: 165px;
    }

    @media @xsmall {
      margin: 0 2px 5px;
      padding-top: 0.5em;
      padding-bottom: 0.5em;
      width: calc(50% - 10px);
    }
  }
}

.facility-nav {
  display: flex;
  align-items: stretch;
  justify-content: center;
  margin-bottom: 80px;
  padding-left: 27px;
  padding-right: 27px;

  @media @xsmall {
    flex-wrap: wrap;
  }

  .button {
    width: 100%;
    max-width: 280px;
    margin: 0 10px;
    flex-grow: 1;
    font-weight: @bold-font-weight;

    @media @xsmall {
      margin: 0 5px 10px;
      width: calc(50% - 10px);
    }

    &.current {
      background-color: @primary;
      color: @white;

      &:after {
        .make-svg-arrow(@white);
      }
    }
  }
}

.facility-head {
  position: relative;
  display: flex;
  flex-direction: row-reverse;
  align-items: center;
  margin-bottom: 80px;
  z-index: 0;

  @media @small-up {
    padding-left: 27px;
  }

  @media @xsmall {
    flex-direction: column;
  }

  &__img {

    @media @small-up {
      flex-shrink: 0;
      width: 50%;
      max-width: calc(50% - 20px);
    }

    @media @xsmall {
      padding-left: 50px;
      margin-bottom: 50px;
    }

    img {
      display: block;
      width: 100%;
      height: 100%;
      object-fit: cover;
      object-position: center 25%;
    }
  }

  &__img-inner {
    position: relative;
    z-index: 0;

    &:after {
      content: '';
      position: absolute;
      bottom: -8.2%;
      left: -80px;
      right: 0;
      height: 35.214%;
      width: auto;
      background-color: @secondary;
      z-index: -1;
      border-radius: 10em 0 0 10em;

      @media @xsmall {
        left: -28px;
        height: 33%;
      }
    }

    @media @small-up {
      height: 426px;
    }
  }

  &__body {
    width: 100%;
    font-feature-settings: "palt";

    @media (min-width: 1365px) {
      position: relative;
      z-index: 0;
    }

    @media @small-up {
      max-width: 500px;
      padding-right: 50px;
    }

    @media @xsmall {
      padding: 0 27px;
    }

    &:before {
      @media @small-up {
        position: absolute;
        content: attr(title);
        top: 0;
        left: 0;
        font-family: 'Play', @sans-serif-font-family;
        font-size: 11.7rem;
        letter-spacing: 0.15em;
        font-weight: @bold-font-weight;
        color: fade(#c8c8c8, 30%);
        line-height: 0.85;
        z-index: -1;
        max-width: 4.5em;
        transform: translateX(0);
      }

      @media (min-width: 1365px) {
        transform: translateX(-41%);
        top: -0.55em;
      }
    }

    p {
      letter-spacing: 0.2em;
      line-height: 1.85;
    }
  }

  &__title {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    font-size: 4.6rem;
    letter-spacing: 0.2em;
    font-weight: @bold-font-weight;
    margin-bottom: 0.5em;

    @media @xsmall {
      font-size: 2.8rem;
    }

    small {
      font-size: 2rem;
      font-family: 'Play', @sans-serif-font-family;
      letter-spacing: 0.21em;
      font-weight: inherit;
      color: @lightgreen;
      line-height: 1.2;

      @media @xsmall {
        font-size: 1.5rem;
      }
    }
  }

}

.facility-point {
  position: relative;
  padding: 60px 25px;
  color: @white;
  background: url('/asset/img/stripe_bg@2x.png') left top / 49px repeat;
  z-index: 0;

  &__title {
    .c-title-underarc;
    color: inherit;
    line-height: 1;
    margin-bottom: 1.3em;

    &:after {
      .make-arc(@white);
    }
  }

  &__list {
    max-width: 960px;
    margin: 0 auto;
    list-style: none;

    @media @small-up {
      display: flex;
      flex-wrap: wrap;
    }
  }

  &__item {
    width: 100%;
    margin: 0;
    display: flex;
    align-items: stretch;
    font-size: 1.7rem;
    letter-spacing: 0.19em;
    color: inherit;
    font-feature-settings: "palt";
    margin-bottom: 40px;

    @media @small-up {
      max-width: 50%;
    }

    @media @xsmall {
      align-items: center;
      font-size: 1.6rem;
    }

    .num {
      flex-shrink: 0;
      display: block;
      width: 41px;
      height: 41px;
      background: url('/asset/img/facility/listicon_tennis.svg') center / contain no-repeat;
      font-style: normal;
      line-height: 41px;
      text-align: center;
      font-family: 'Roboto', @sans-serif-font-family;
      font-size: 2rem;
      letter-spacing: 0;
      text-indent: 0;
      font-weight: @bold-font-weight;
      color: #135d5d;

      @media @small-up {
        margin-top: 0.2em;
      }
    }

    .text {
      margin-top: 0;
      color: inherit;
      font-size: inherit;
      letter-spacing: inherit;
      font-weight: @bold-font-weight;
      padding-left: 1.5em;
      line-height: 1.76;
      margin-right: -0.5em;

      @media @xsmall {
        padding-left: 1em;
        line-height: 1.43;
      }

      strong {
        color: @secondary;
      }
    }
  }

  &__btnwrap {
    @media @small-up {
      padding-left: 66px;
      margin-top: -20px;
    }
  }

  &__bg {
    position: absolute;
    z-index: -1;
    overflow: hidden;

    svg {
      @media @xsmall {
        max-width: 100%;
        max-height: 100%;
      }
    }
  }

  &__bg.is-bg-01 {
    top: -80px;
    left: 50px;

    @media @xsmall {
      width: 82px;
      height: 76px;
      top: -40px;
      left: 12px;
    }
  }

  &__bg.is-bg-02 {
    bottom: -125px;
    right: 0;
    max-width: 250px;

    @media @xsmall {
      max-width: 100px;
      height: 90px;
      bottom: -38px;

      svg {
        max-width: 120%;
      }
    }
  }
}

.facility-equipment {
  background-color: #f6f6f6;
  padding: 100px 27px;
  margin-bottom: 100px;

  @media @xsmall {
    padding-top: 70px;
    padding-bottom: 70px;
    margin-bottom: 60px;
  }

  &__title {
    .c-title-underarc;
    margin-bottom: 1.5em;
  }

  &__list {
    list-style: none;
    padding: 0;
    margin: 0 auto;
    display: flex;
    flex-wrap: wrap;
    max-width: 996px;
  }

  &__item {
    width: 100%;
    margin: 0 0 50px;
    font-feature-settings: "palt";

    @media @small-up {
      width: 50%;
      padding: 0 18px;
    }

    .name {
      text-align: center;
      color: @primary;
      font-size: 2.5rem;
      font-weight: @bold-font-weight;
      letter-spacing: 0.23em;
      text-indent: 0.23em;
      margin-bottom: 0.6em;

      @media @xsmall {
        font-size: 2.1rem;
      }

      &+.text {
        margin-top: 0;
      }
    }

    .text {
      line-height: 1.85;
      letter-spacing: 0.15em;
    }

  }

  &__item-img {
    margin-bottom: 1.5em;

    img {
      display: block;
      max-width: 100%;
      margin: 0 auto;
    }
  }
}

.facility-price {
  padding: 0 27px;
  max-width: 1014px;
  margin: 120px auto 60px;

  @media @xsmall {
    margin-top: 60px;
  }

  &__title {
    .c-title-underarc;
    margin-bottom: 1.5em;
  }

  &__lead {
    font-size: 1.6rem;
    letter-spacing: 0.175em;

    @media @small-up {
      text-align: center;
      font-weight: @bold-font-weight;
    }

    @media @xsmall {
      font-size: 1.4rem;
    }
  }

  &__title+&__lead {
    margin-top: -1em;
    margin-bottom: 3em;

    @media @xsmall {
      margin-bottom: 2em;
    }
  }

  &__tableTitle {
    font-size: 2.6rem;
    font-weight: @bold-font-weight;
    letter-spacing: 0.1em;
    color: #135d5d;
    margin-bottom: 0.5em;

    @media @xsmall {
      font-size: 2.3rem;
    }

    &+.notice {
      margin-top: -0.5em;
    }
  }

  .notice {
    margin-bottom: 1em;
  }

  &__table {
    border: 1px solid #bebfbf;
    max-width: 960px;
    margin: 0 auto 2.5em;
    font-feature-settings: "palt";
    caption-side: bottom;

    caption {
      font-size: 1.4rem;
      letter-spacing: 0.1em;

      @media @xsmall {
        font-size: 1.2rem;
      }
    }

    thead th {
      background-color: @primary;
      color: @white;
      border-width: 0;
      text-align: center;
      font-weight: @bold-font-weight;
      font-size: 1.8rem;

      @media @xsmall {
        font-size: 1.4rem;
      }
    }

    tbody {

      th,
      td {
        border: solid #bebfbf;
        border-width: 1px 0;
        font-size: 1.8rem;
        text-align: center;
        letter-spacing: 0.1em;

        @media @xsmall {
          font-size: 1.4rem;
        }
      }

      th {
        background-color: #e7f5c9;
        font-weight: @bold-font-weight;

        @media @small-up {
          width: 33.333%;
        }
      }
    }

    sup {
      font-weight: @regular-font-weight;
      font-size: 0.5em;
    }
  }

  &__rentalTable {
    border-width: 0;
    max-width: 960px;
    margin: 0 auto 2.5em;
    font-feature-settings: "palt";
    caption-side: bottom;

    caption {
      font-size: 1.4rem;
      letter-spacing: 0.1em;

      @media @xsmall {
        font-size: 1.2rem;
      }
    }

    th,
    td {
      border: 1px solid #bebfbf;
      font-size: 1.8rem;
      letter-spacing: 0.15em;

      @media @xsmall {
        font-size: 1.4rem;
      }
    }

    th {
      background-color: @primary;
      color: @white;
      text-align: center;
      font-weight: @bold-font-weight;

      @media @xsmall {
        line-height: 1.2;
      }
    }

    td {
      @media @small-up {
        padding-left: 3em;
      }
    }
  }

  &__desc {
    display: flex;
    justify-content: center;
    align-items: stretch;
    flex-wrap: wrap;

    @media @small-up {
      margin-left: -18px;
      margin-right: -18px;
    }

  }

  &__descItem {
    width: 296px;
    margin: 0 18px 36px;
    font-feature-settings: "palt";

    @media @small-up {
      display: flex;
      flex-direction: column;
      max-width: calc(33.333% - 36px);
    }

    @media @xsmall {
      margin-left: auto;
      margin-right: auto;
    }

    .img {
      display: block;
      max-width: 100%;
      margin: 0 auto;
      flex-shrink: 0;
    }

    .body {
      padding: 1.5em 1.8em 1.8em;
      margin: 0;
      background-color: #e7f5c9;
      flex-grow: 1;

      dt {
        text-align: center;
        font-size: 2.1rem;
        font-weight: @bold-font-weight;
        letter-spacing: 0.18em;
      }

      dd {
        font-size: 1.4rem;
        letter-spacing: 0.18em;
      }
    }
  }

  &__btnwrap {
    display: flex;
    align-items: center;
    justify-content: center;

    .button {
      font-weight: @bold-font-weight;
      width: 100%;
      max-width: 267px;

      @media @small-up {
        font-size: 2rem;
        height: 73px;
        max-width: 414px;
      }
    }

    .button .icon {
      margin-right: 0.5em;
    }

    .button-inverse .icon {
      fill: @white;
    }
  }
}

.facility-calendar {
  position: relative;
  background-color: #e7f5c9;
  padding: 70px 27px 150px;
  margin-bottom: 80px;
  z-index: 0;

  @media @xsmall {
    margin-bottom: 60px;
  }

  &:after {
    @arc: escape('<svg xmlns="http://www.w3.org/2000/svg" preserveAspectRatio="none" viewBox="0 0 1400 67"><path fill="@{white}" d="M1400,67C1269.48,27.21,1005,0,700,0S130.52,27.21,0,67Z"/></svg>');
    content: '';
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 67px;
    max-height: 100%;
    background: center bottom / 100% 100% no-repeat;
    background-image: url('data:image/svg+xml;charset=UTF-8,@{arc}');

    @media @xsmall {
      height: 20px;
    }
  }

  @media @xsmall {
    padding-bottom: 80px;
  }

  &__title {
    .c-title-underarc;
    margin-bottom: 1.5em;
    padding-bottom: 0.5em;

    @media @xsmall {
      font-size: 2.5rem;
      margin-left: -0.5em;
      margin-right: -0.5em;
    }

    &:after {
      .make-arc(@lightgreen);
    }
  }

  &__lead {
    margin-bottom: 3em;
    line-height: 1.85;
    letter-spacing: 0.155em;

    @media @small-up {
      text-align: center;
    }
  }

  &__title+&__lead {
    margin-top: -1em;
  }

  &__body {
    max-width: 593px;
    margin: 0 auto 50px;
  }

  &__contact {
    display: flex;
    justify-content: center;
    font-feature-settings: "palt";
    margin-bottom: 50px;

    @media @xsmall {
      flex-direction: column;
    }

    &__body {
      @media @small-up {
        padding-right: 35px;
      }

      @media @xsmall {
        margin-bottom: 30px;
      }

      b {
        font-size: 1.9rem;
        color: #135d5d;
        font-weight: @bold-font-weight;
        letter-spacing: 0.185em;

        @media @xsmall {
          font-size: 1.6rem;
        }
      }
    }

    .tel {
      margin: 0;
      font-size: 1.2rem;
      font-family: 'Roboto', @sans-serif-font-family;
      letter-spacing: 0.08em;
      font-size: 4rem;
      color: @primary;
      font-weight: @medium-font-weight;

      @media @xsmall {
        font-size: 3.3rem;
      }

      span {
        font-size: 2.1rem;
        letter-spacing: 0.08em;

        @media @xsmall {
          font-size: 1.9rem;
        }
      }

      @media @small-up {
        text-indent: -1.3em;
      }
    }

    .opentime {
      display: block;
      font-size: 1.2rem;
      letter-spacing: 0.2em;
      line-height: 1.75;

      @media @xsmall {
        padding-left: 3.8em;
      }
    }

    &__cancel {
      display: flex;
      align-items: center;
      font-size: 1rem;
      line-height: 1.7;

      @media @small-up {
        padding-left: 35px;
        border-left: 1px solid @lightgreen;
      }

      b {
        display: block;
        font-size: 1.6rem;
        font-weight: @bold-font-weight;
        letter-spacing: 0.175em;
        margin-bottom: 0.5em;

        @media @xsmall {
          font-size: 1.4rem;
        }
      }

      p {
        margin: 0;
        padding-left: 1em;
        text-indent: -1em;
        font-size: inherit;
        letter-spacing: 0.155em;

        &:before {
          content: '※';
          display: inline-block;
          width: 1em;
          text-indent: 0;
        }
      }
    }
  }

  &__btnwrap {
    display: flex;
    align-items: center;
    justify-content: center;

    .button {
      font-weight: @bold-font-weight;
      width: 100%;
      max-width: 267px;

      @media @small-up {
        font-size: 2rem;
        height: 73px;
        max-width: 414px;
      }
    }

    .button .icon {
      margin-right: 0.5em;
    }

    .button-inverse .icon {
      fill: @white;
    }
  }
}

.facility-banners {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 80px 0;
  padding: 0 27px;

  .banner {
    .hoverOpacity;
  }
}