.c-opentime {
  padding: 60px 27px 0;
  margin-bottom: 100px;

  @media @xsmall {
    margin-bottom: 80px;
  }

  &__inner {
    position: relative;
    max-width: 960px;
    margin: 0 auto;
    padding: 50px 30px 30px;
    border: 3px solid #135d5d;
    z-index: 0;

    @media @xsmall {
      padding-top: 40px;
    }
  }

  &__title {
    position: absolute;
    top: -60px;
    left: 50%;
    z-index: 1;
    margin: 0;
    transform: translateX(-50%);
    background: center / contain no-repeat;
    background-image: url('/asset/img/opentime_title@2x.png');
    width: 290px;
    height: 88px;

    @media @xsmall {
      background-image: url('/asset/img/opentime_title_sp@2x.png');
      width: 228px;
      height: 84px;
      top: -61px;
    }

    span {
      visibility: hidden;
      font: 0 / 0 a;
      color: transparent;
    }
  }

  &__body {
    text-align: center;
    font-feature-settings: "palt";
  }

  &__body+&__body {
    margin-top: 1.5em;

    @media @xsmall {
      margin-top: 1em;
    }
  }

  &__note {
    max-width: 818px;
    margin: 30px auto 0;
    padding-top: 25px;
    text-align: center;
    font-feature-settings: "palt";
    border-top: 1px dashed #bcbdbd;

    @media @xsmall {
      margin-top: 18px;
      padding-top: 15px;
    }

    .title {
      font-size: 2.2rem;
      letter-spacing: 0.185em;
      text-indent: 0.185em;
      margin-bottom: 0.5em;
      font-weight: @bold-font-weight;
      color: #135d5d;

      @media @xsmall {
        font-size: 1.7rem;
      }

      &+p {
        margin-top: 0;
      }
    }

    p {
      line-height: 1.73;
      letter-spacing: 0.2em;
      font-size: 1.5rem;

      @media @xsmall {
        font-size: 1.4rem;
        letter-spacing: 0.1em;
        text-align: left;
      }
    }

    p strong {
      @media @small-up {
        font-size: 1.4em;
      }
    }
  }

  .time {
    font-size: 3.2rem;
    font-family: 'Roboto', @sans-serif-font-family;
    letter-spacing: 0.1em;
    font-weight: @bold-font-weight;

    @media @xsmall {
      font-size: 2.5rem;
      margin-bottom: 0.5em;
    }

    mark {
      display: inline-block;
      background-color: #135d5d;
      min-width: 121px;
      font-size: 1.8rem;
      font-weight: @bold-font-weight;
      color: @white;
      text-align: center;
      letter-spacing: 0.15em;
      text-indent: 0.15em;

      @media @small-up {
        margin-right: 1em;
        padding-top: 0.5rem;
        padding-bottom: 0.5rem;
      }

      @media @xsmall {
        margin-bottom: 0.5em;
        font-size: 1.4rem;
      }
    }

    strong {
      font-family: inherit;
      background: linear-gradient(to top, @secondary 35%, transparent 0);
    }

    small {
      font-size: 0.5em;

      @media @xsmall {
        display: block;
        margin-top: 0.2em;
      }
    }

    strong+small {
      margin-left: 0.2em;
    }
  }

  .notice {
    font-weight: @bold-font-weight;
    font-size: 1.7rem;
    letter-spacing: 0.2em;

    @media @xsmall {
      font-size: 1.5rem;
    }

    small {
      font-size: 1.2rem;
      letter-spacing: 0.175em;
      font-weight: @regular-font-weight;

      @media @xsmall {
        display: block;
        margin-top: 0.5em;
        text-align: left;
        padding-left: 0.8em;
        text-indent: -0.8em;
      }
    }
  }

  .time+.notice {
    margin-top: 0;
  }
}