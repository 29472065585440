@keyframes pathAnim {
  0% {
    stroke-dashoffset: 1000;
  }

  100% {
    stroke-dashoffset: 0;
  }
}

.anim-path {
  stroke-dasharray: 1000;
  stroke-dashoffset: 1000;
  transition: all 4.5s linear;

  .is-active & {
    // animation: pathAnim 4.5s linear forwards;
    stroke-dashoffset: 0;
  }
}