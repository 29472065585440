@keyframes table_scroll_arrow {
  0% {
    transform: translateX(0);
  }

  10% {
    transform: translateX(12px);
  }

  20% {
    transform: translateX(0);
  }
}

.is--process {
  // 1カラム化
  .hybs-one-column(100%);

  .breadcrumbs,
  .detailfooter {
    display: none;
  }

  #main .entry {
    overflow: visible;
  }
}

.process-nav {
  max-width: 1014px;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  padding: 0 15px;
  margin: 50px auto;

  @media @xsmall {
    margin-top: 30px;
    margin-bottom: 40px;
  }

  .button {
    font-weight: @bold-font-weight;
    font-size: 1.2rem;
    min-width: calc(30% - 12px);

    @media @small-up {
      margin: 0 6px 12px;
    }

    @media @xsmall {
      margin: 0 2px 5px;
      width: calc(50% - 4px);
      letter-spacing: 0.08em;
      padding-top: 0.2em;
      padding-bottom: 0.2em;

      &:after {
        right: 0.6em;
      }
    }
  }
}

.process-lead {
  text-align: center;
  font-weight: @bold-font-weight;
  font-size: 2.1rem;
  letter-spacing: 0.16em;
  margin-bottom: 50px;
  font-feature-settings: "palt";

  @media @xsmall {
    font-size: 1.5rem;
    margin-bottom: 30px;

    &:first-child {
      margin-top: -1em;
    }
  }
}

.process-section {
  max-width: 1200px;
  margin: 0 auto 170px;

  @media @xsmall {
    margin-bottom: 80px;

    &.is-tabletennis {
      margin-bottom: 0;
    }
  }

  &__title {
    position: relative;
    font-size: 2.8rem;
    letter-spacing: 0.175em;
    text-indent: 0.175em;
    font-weight: @bold-font-weight;
    background-color: #135d5d;
    color: @white;
    text-align: center;
    padding: 0.75em;
    margin: 0;

    @media @xsmall {
      padding: 1.2em 0.75em;
    }

    &:after {
      .arrow-bottom(10px, #135d5d);
      z-index: 1;
    }

    @media @xsmall {
      font-size: 1.9rem;
      letter-spacing: 0.1em;
      text-indent: 0.1em;
    }
  }

  &__list {
    list-style: none;
    padding: 0;
    margin: 0;
  }

  &__step {
    position: relative;
    padding: 65px 115px;
    margin: 0;

    @media @xsmall {
      padding: 60px 25px;
    }

    &:after {
      z-index: 1;
    }

    &-row {
      display: flex;
      align-items: center;

      @media @xsmall {
        flex-direction: column;
      }
    }

    &-img {
      position: relative;
      flex-shrink: 0;

      @media @small-up {
        margin-right: 40px;
      }

      @media @xsmall {
        margin-bottom: 40px;
      }

      .num {
        position: absolute;
        z-index: 1;
        background: center / contain no-repeat;
        text-align: center;
        font-size: 3rem;
        font-family: 'Roboto', @sans-serif-font-family;
        letter-spacing: 0;
        font-style: normal;
        font-weight: @bold-font-weight;

        @media @small-up {
          top: 50%;
          left: 0;
          transform: translate(-65%, -50%);
        }

        @media @xsmall {
          font-size: 1.9rem;
          top: 100%;
          left: 50%;
          transform: translate(-50%, -50%);
        }

        .is-tennis & {
          background-image: url('/asset/img/process/listicon_tennis.svg');
          color: #135d5d;
          line-height: 72px;
          width: 72px;
          height: 72px;

          @media @xsmall {
            width: 47px;
            height: 47px;
            line-height: 47px;
          }
        }

        .is-tabletennis & {
          background-image: url('/asset/img/process/listicon_tabletennis.svg');
          color: #f6ef3a;
          line-height: 60px;
          text-indent: 3px;
          width: 68px;
          height: 99px;

          @media @xsmall {
            width: 43px;
            height: 63px;
            line-height: 38px;
            text-indent: 0.1em;
          }
        }
      }

      img {
        display: block;
        max-width: 100%;
        margin: 0 auto;
      }
    }

    &-body {
      font-feature-settings: "palt";

      .title {
        font-size: 2.6rem;
        font-weight: @bold-font-weight;
        color: #135d5d;
        letter-spacing: 0.16em;
        margin-bottom: 0.5em;

        @media @xsmall {
          font-size: 2.1rem;
        }

        &+p {
          margin-top: 0;
        }
      }

      p {
        line-height: 1.85;
        letter-spacing: 0.2em;

        &+.notice {
          margin-top: -1em;
        }
      }

      .notice {
        small {
          display: block;
          padding-left: 1em;
          text-indent: -1em;

          &:before {
            content: '※';
            display: inline-block;
            text-indent: 0;
            width: 1em;
          }

        }
      }
    }

    &-accordion {
      .trigger {
        position: relative;
        display: block;
        width: 100%;
        border: 1px solid #a8a8a9;
        color: @primary;
        font-weight: @bold-font-weight;
        letter-spacing: 0.235em;

        @media @xsmall {
          height: auto;
        }

        &:hover,
        &:focus {
          color: @primary;
        }

        &:focus {
          background-color: @white;
        }

        &:before,
        &:after {
          content: '';
          position: absolute;
          top: 50%;
          right: 1.5em;
          width: 12px;
          height: 1px;
          background-color: @primary;
          transition: all @transition-fast ease-in-out;
        }

        &:before {
          transform: rotate(-90deg);
        }

        &:after {
          transform: rotate(-180deg);
        }

        &.is-active:before,
        &.is-active:after {
          transform: rotate(0deg);
        }
      }
    }

    &-notice {
      max-width: 800px;
      margin: 40px auto 0;
      border: 1px solid #135d5d;
      display: flex;

      @media @small-up {
        padding: 35px;
        align-items: center;
      }

      @media @xsmall {
        padding: 25px;
        flex-direction: column;
      }

      .title {
        color: #135d5d;
        font-size: 2.5rem;
        letter-spacing: 0.2em;
        margin: 0;
        font-weight: @bold-font-weight;

        @media @small-up {
          margin-left: 0.5em;
          margin-right: 1.5em;
        }

        .fa {
          letter-spacing: 0.1em;
        }
      }
    }
  }

  &__step:nth-child(odd) {
    background-color: #e7f5c9;

    &:after {
      .arrow-bottom(20px, #e7f5c9);
    }
  }

  &__step:nth-child(even) {
    &:after {
      .arrow-bottom(20px, @white);
    }
  }

  &__step:last-child:after {
    display: none;
  }
}

.process-price {

  &__tableTitle {
    font-size: 2.3rem;
    font-weight: @bold-font-weight;
    letter-spacing: 0.1em;
    color: #135d5d;
    margin-bottom: 0.5em;

    @media @xsmall {
      font-size: 2.3rem;
    }

    &+.notice {
      margin-top: -0.5em;
    }

    &:first-child {
      margin-top: 1em;
    }
  }

  .notice {
    margin-bottom: 1em;
  }

  &__table {
    border: 1px solid #bebfbf;
    max-width: 960px;
    margin: 0 auto 2em;
    font-feature-settings: "palt";
    caption-side: bottom;

    caption {
      font-size: 1.3rem;
      letter-spacing: 0.1em;

      @media @xsmall {
        font-size: 1.2rem;
      }
    }

    thead th {
      background-color: @primary;
      color: @white;
      border-width: 0;
      text-align: center;
      font-weight: @bold-font-weight;
      font-size: 1.6rem;

      @media @xsmall {
        font-size: 1.4rem;
      }
    }

    tbody {

      th,
      td {
        border: solid #bebfbf;
        border-width: 1px 0;
        font-size: 1.6rem;
        text-align: center;
        letter-spacing: 0.1em;

        @media @xsmall {
          font-size: 1.4rem;
        }
      }

      th {
        background-color: #f6f6f6;
        font-weight: @bold-font-weight;

      }

      td {
        background-color: @white;
      }
    }

    sup {
      font-weight: @regular-font-weight;
      font-size: 0.5em;
    }
  }

  &__rentalTable {
    border-width: 0;
    max-width: 960px;
    margin: 0 auto 2.5em;
    font-feature-settings: "palt";
    caption-side: bottom;

    &:first-child {
      margin-top: -1px;
    }

    &:last-child {
      margin-bottom: 0;
    }

    caption {
      font-size: 1.4rem;
      letter-spacing: 0.1em;

      @media @xsmall {
        font-size: 1.2rem;
      }
    }

    th,
    td {
      border: 1px solid #bebfbf;
      font-size: 1.6rem;
      letter-spacing: 0.15em;

      @media @xsmall {
        font-size: 1.4rem;
      }
    }

    th {
      background-color: @primary;
      color: @white;
      text-align: center;
      font-weight: @bold-font-weight;

      @media @xsmall {
        line-height: 1.2;
      }
    }

    td {
      background-color: @white;
    }
  }
}

.step-listblock {
  list-style: none;
  font-feature-settings: "palt";

  li {
    margin: 0 0 0.6em;
    letter-spacing: 0.12em;
    font-weight: @bold-font-weight;
    padding-left: 1.3em;
    text-indent: -1.3em;

    &:before {
      content: '\f10c';
      font-family: FontAwesome;
      color: @lightgreen;
      display: inline-block;
      width: 1.3em;
      text-indent: 0;
    }

    &:last-child {
      margin-bottom: 0;
    }

    span {
      font-weight: @regular-font-weight;
    }
  }
}

.process-member,
.process-ticket {
  padding-left: 27px;
  padding-right: 27px;

  &__title {
    .c-title-underarc;
  }

  &__head {
    display: flex;
    align-items: center;
    justify-content: center;
    font-feature-settings: "palt";
    margin-bottom: 40px;

    @media @xsmall {
      flex-direction: column;
      align-items: stretch;
    }

    >img {
      flex-shrink: 0;
      display: block;
      max-width: 100%;

      @media @xsmall {
        margin-bottom: 20px;
      }
    }

    p {
      line-height: 1.75;
      letter-spacing: 0.1em;
    }
  }

}

.process-member {
  position: relative;
  background-color: #f6f6f6;
  overflow: hidden;
  z-index: 0;
  padding-bottom: 80px;
  margin-bottom: 100px;

  @media @small-up {
    padding-top: 130px;
  }

  @media @xsmall {
    padding-top: 80px;
    margin-bottom: 80px;
  }

  &__title:before {
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
    content: attr(title);
    font-family: 'Play', @sans-serif-font-family;
    letter-spacing: 0.075em;
    font-weight: @bold-font-weight;
    color: fade(#c7c7c7, 30%);
    line-height: 0.85;
    z-index: -1;

    @media @small-up {
      top: -0.45em;
      font-size: 11.7rem;
    }

    @media @xsmall {
      top: -0.4em;
      font-size: 4.4rem;
    }
  }

  &__priceList {
    display: flex;
    align-items: flex-start;
    justify-content: center;
    flex-wrap: wrap;
    margin-bottom: 80px;
    font-feature-settings: "palt";

    @media @xsmall {
      flex-direction: column;
    }

    .notice {
      width: 100%;
      letter-spacing: 0.125em;

      @media @small-up {
        text-align: right;
      }

      @media @xsmall {
        font-size: 1.2rem;
        margin-top: 0;
        letter-spacing: 0.07em;
      }

      p {
        max-width: 960px;
        margin: 0.7em auto 0;
        font-size: inherit;
        letter-spacing: inherit;
      }
    }
  }

  &__priceTable {
    width: 310px;
    max-width: calc(33.333% - 14px);
    margin: 0 7px;
    border-width: 0;

    @media @xsmall {
      width: 100%;
      max-width: 100%;
      margin: 0 auto 15px;
    }

    thead th {
      background-color: @primary;
      color: @white;
      text-align: center;
      font-size: 2rem;
      letter-spacing: 0.185em;
      font-weight: @bold-font-weight;
      fill: @white;
      border-width: 0;

      @media @xsmall {
        font-size: 1.6rem;
      }

      .icon {
        vertical-align: middle;

        @media @xsmall {
          height: 22px;
        }
      }
    }

    tbody {

      th,
      td {
        border: solid #cfd0d0;
        border-width: 1px 0;
        font-size: 1.8rem;
        text-align: center;
        font-weight: @bold-font-weight;

        @media @xsmall {
          font-size: 1.5rem;
        }
      }

      th {
        background-color: #e7f5c9;
      }

      td {
        background-color: @white;
      }

      tr:first-child th,
      tr:first-child td {
        border-top-width: 0;
      }

      tr:last-child th,
      tr:last-child td {
        border-bottom-width: 0;
      }
    }

    sup {
      font-weight: @regular-font-weight;
    }
  }
}

.process-ticket {
  margin-bottom: 150px;

  @media @xsmall {
    margin-bottom: 80px;
  }

  &__prices {
    max-width: 100%;

    @media @small-up {
      width: 416px;
      margin-top: 50px;
    }

    @media @xsmall {
      margin-bottom: 50px;
    }
  }

  &__price {
    display: flex;
    align-items: center;
    justify-content: space-between;
    border: solid #cfd0d0;
    border-width: 1px 0;
    margin: 0;

    dt,
    dd {
      margin: 0;
      letter-spacing: 0.1em;

      @media @xsmall {
        font-size: 1.3rem;
      }
    }

    dt {
      color: @primary;
      padding: 1em;
      font-size: 1.7rem;

      @media @xsmall {
        font-size: 1.4rem;
      }
    }

    b {
      font-size: 1.3em;
    }
  }

  &__price+&__price {
    border-top-width: 0;
  }

}

.process-benefit {
  max-width: 960px;
  margin: 0 auto;

  &__title {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 3.2rem;
    color: #135d5d;
    font-weight: @bold-font-weight;

    @media @xsmall {
      font-size: 2.5rem;
    }

    &:before,
    &:after {
      display: block;
      content: '';
      height: 2px;
      width: auto;
      flex-grow: 1;
      background-color: #135d5d;
      margin-top: -0.3em;
    }

    .icon {
      position: absolute;
      left: -60px;
      top: -70px;

      @media @xsmall {
        width: 100px;
        height: 100px;
        left: -40px;
        top: -40px;
      }
    }

    span {
      position: relative;
      text-align: center;
      padding-left: 1em;
      padding-right: 1em;

      @media @xsmall {
        padding-left: 0.4em;
        padding-right: 0.4em;
      }
    }

    small {
      display: block;
      font-size: 1.1rem;
      font-family: 'Play', @sans-serif-font-family;
      letter-spacing: 0.1em;
      text-indent: 0.1em;
      color: @lightgreen;
      font-weight: @bold-font-weight;
    }
  }

  &__lead {
    text-align: center;
    line-height: 1.75;
    letter-spacing: 0.2em;
  }

  &__tableWrap {
    position: relative;
    max-width: 960px;
    margin: 0 auto;
    font-feature-settings: "palt";

    &.is-scrollable {
      @media @xsmall {
        margin-top: 2.5em;
      }
    }

    &:after {
      content: "";
      position: absolute;
      right: 0;
      bottom: calc(100% + 5px);
      width: 128px; //258
      height: 17px; //46
      background: url('/asset/img/table_scroll@2x.png') 50%/contain no-repeat;
      animation: table_scroll_arrow 3s ease-in-out infinite forwards;

      @media @small-up {
        display: none;
      }
    }
  }

  &__table {
    caption-side: bottom;
    font-feature-settings: "palt";

    @media @xsmall {
      width: auto;
      margin-top: 0;
    }

    &-inner {
      @media @xsmall {
        overflow: auto;
      }
    }

    caption {
      text-align: right;
      font-size: 1.4rem;
      letter-spacing: 0.12em;

      @media @xsmall {
        font-size: 1.2rem;
      }
    }

    thead th {
      background-color: @primary;
      color: @white;
      text-align: center;
      font-size: 2rem;
      letter-spacing: 0.185em;
      font-weight: @bold-font-weight;
      fill: @white;
      border-width: 0;

      @media @xsmall {
        font-size: 1.4rem;
      }

    }

    tbody {
      tr {
        border: 1px solid #cfd0d0;
      }

      th,
      td {
        border-width: 0;
        background-color: @white;
        letter-spacing: 0.15em;

        @media @small-up {
          text-align: center;
        }

        @media @xsmall {
          font-size: 1.3rem;
          white-space: nowrap;
        }
      }

      th {
        font-weight: @bold-font-weight;
        background-color: #e7f5c9;
        font-size: 1.8rem;

        @media @xsmall {
          font-size: 1.4rem;
        }
      }

      td {
        @media @small-up {
          width: 25%;
        }
      }

      b {
        font-size: 1.8rem;

        @media @xsmall {
          font-size: 1.6rem;
        }
      }
    }

    .is-to-icon {
      position: relative;
      background-clip: padding-box;

      &:after {
        .arrow-right(7px, @secondary);
        left: 0;
      }
    }

    mark {
      width: 155px;
      text-align: center;
      font-weight: @bold-font-weight;
      font-size: 1.8rem;
      display: inline-block;
      letter-spacing: 0.1em;
      font-family: 'Roboto', @sans-serif-font-family;

      @media @xsmall {
        width: 86px;
        font-size: 1.3rem;
      }
    }
  }

  &__present-head {
    text-align: center;
    font-size: 1.8rem;
    font-weight: @bold-font-weight;
    letter-spacing: 0.175em;

    @media @xsmall {
      font-size: 1.6rem;
    }

    b {
      font-family: 'Roboto', @sans-serif-font-family;
      font-size: 2em;
      font-weight: 900;
    }
  }

  &__present-text {
    text-align: center;
    font-size: 2.1rem;
    font-weight: @bold-font-weight;
    letter-spacing: 0.175em;

    @media @xsmall {
      font-size: 1.6rem;
    }
  }

  &__presents {
    display: flex;
    align-items: stretch;
    justify-content: center;
    list-style: none;
    font-feature-settings: "palt";

    @media @xsmall {
      flex-direction: column;
    }
  }

  &__present {
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 0 40px;
    text-align: center;
    font-size: 2.5rem;
    font-weight: @bold-font-weight;
    letter-spacing: 0.15em;
    padding: 0.7em 1em;
    background-color: #e7f5c9;

    @media @xsmall {
      font-size: 1.5rem;
      min-height: 65px;
    }

    small {
      font-size: 0.8em;

      @media @small-up {
        font-weight: @regular-font-weight;
      }
    }

    @media @xsmall {
      margin-left: 0;
      margin-right: 0;
      margin-bottom: 35px;
    }
  }

  &__present+&__present {
    position: relative;

    &:before {
      position: absolute;
      content: 'or';
      line-height: 0;
      top: 50%;
      left: -40px;
      transform: translateX(-50%);
      color: #135d5d;
      font-family: 'Roboto';
      font-weight: 900;
      font-size: 3.6rem;
      letter-spacing: 0.065em;
      text-indent: 0.065em;

      @media @xsmall {
        font-size: 2.5rem;
        top: -19px;
        left: 50%;
      }
    }
  }
}