// ページング(prev/next)
@paging-button-bg: transparent;
@paging-button-hover-bg: transparent;
@paging-button-disable-bg: transparent;
@paging-button-border: 0px solid @body-color;
@paging-button-hover-border: 0px;
@paging-button-disable-border: 0px solid @light-grey;
@paging-button-color: @dark;
@paging-button-hover-color: @dark;
@paging-button-disable-color: tint(@body-color, 20%);
@paging-button-font-family: @sans-serif-font-family;
@paging-button-font-weight: @bold-font-weight;

@paging-backbtn-bg: transparent;
@paging-backbtn-hover-bg: @dark;
@paging-backbtn-color: @dark;
@paging-backbtn-hover-color: @white;
@paging-backbtn-border: solid @dark;

@paging-num-color: @dark;
@paging-num-hover-color: @white;
@paging-num-bg: transparent;
@paging-num-hover-bg: @dark;
@paging-icon-color: @dark;
@paging-icon-hover-color: @white;

@doticon: escape('<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 30.27 27.6"><defs><style>.c{fill:@{light-grey};}</style></defs><g><circle class="c" cx="2.67" cy="2.67" r="2.67"/><circle class="c" cx="15.14" cy="2.67" r="2.67"/><circle class="c" cx="27.6" cy="2.67" r="2.67"/><circle class="c" cx="2.67" cy="13.8" r="2.67"/><circle class="c" cx="15.14" cy="13.8" r="2.67"/><circle class="c" cx="27.6" cy="13.8" r="2.67"/><circle class="c" cx="2.67" cy="24.93" r="2.67"/><circle class="c" cx="15.14" cy="24.93" r="2.67"/><circle class="c" cx="27.6" cy="24.93" r="2.67"/></g></svg>');

@arrow-right: escape('<svg xmlns="http://www.w3.org/2000/svg" width="20" height="40" viewBox="0 0 21 41"><polyline points="0.5,0.5 20.5,20.5 0.5,40.5" stroke="@{paging-button-color}" fill="none" stroke-miterlimit="10"/></svg>');
@arrow-right-on: escape('<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 21 41"><polyline points="0.5,0.5 20.5,20.5 0.5,40.5" stroke="@{white}" fill="none" stroke-miterlimit="10"/></svg>');

@arrow-left: escape('<svg xmlns="http://www.w3.org/2000/svg" width="20" height="40" viewBox="0 0 21 41"><polyline points="20.5,0.5 0.5,20.5 20.5,40.5" stroke="@{paging-button-color}" fill="none" stroke-miterlimit="10"/></svg>');
@arrow-left-on: escape('<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 21 41"><polyline points="20.5,0.5 0.5,20.5 20.5,40.5" stroke="@{white}" fill="none" stroke-miterlimit="10"/></svg>');

#container .paginationbox{
  margin: 50px 0;
  .pageNav{
    width: auto;
    margin: 0 auto;
    border: 1px solid @dark;
    border-width: 1px 0;
    display: flex;
    justify-content: center;
  }
  .prev,.next{
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    max-width: 480px;
    font-size: 1.5rem;
    line-height: 1em;
    font-weight: @paging-button-font-weight;
    font-family: 'Roboto',sans-serif;
    color: @paging-button-color;
    font-family: @paging-button-font-family;
    letter-spacing: .2em;
    text-indent: 0.2em;
    background: @paging-button-bg;
    border: @paging-button-border;
    .transition(background @transition-fast ease-in-out);
    >span{
      flex-grow: 1;
      display: block;
      padding: 1em;
      font-size: 1.2rem;
      letter-spacing: 0.1em;
      text-indent: 0;
      text-align: left;
      font-weight: 400;
      order: 1;
    }
    .thumb{
      max-height: 85px;
      max-width: 120px;
      overflow: hidden;
      @media @xsmall {
        display: none;
      }
      img{
        display: block;
        width: 100%;
        object-fit: cover;
      }
    }
    &:hover,&:focus{
      text-decoration: none;
    }
  }
  .prev > .thumb{
    order: 0;
  }
  .next > .thumb{
    order: 1;
  }
  .prev:before,
  .next:after{
    content: '';
    display: block;
    width: 20px;
    height: 40px;
    background: center / contain no-repeat;
    flex-shrink: 0;
    @media @xsmall {
      width: 13px;
      height: 25px;
    }
  }
  .prev:before{
    margin-right: 1.5em;
    background-image: url('data:image/svg+xml;charset=UTF-8,@{arrow-left}');
    order: 0;
    @media @xsmall {
      margin-right: 0.6em;
    }
  }
  .next:after{
    margin-left: 1.5em;
    background-image: url('data:image/svg+xml;charset=UTF-8,@{arrow-right}');
    order: 2;
    @media @xsmall {
      margin-left: 0.6em;
    }
  }
  .prev:not(.disable):before,
  .next:not(.disable):after{
    color: @paging-icon-color;
  }
  .prev.disable,.next.disable{
    color: @paging-button-disable-color;
    background: @paging-button-disable-bg;
    border: @paging-button-disable-border;
    opacity: 0.7;
  }
  .prev:hover:not(.disable),
  .next:hover:not(.disable){
    text-decoration: none;
    color: @paging-num-hover-color;
    background-color: @paging-num-hover-bg;
    &:before, &:after{
      color: @paging-icon-hover-color;
    }
  }
  .prev:hover:not(.disable):before{
    background-image: url('data:image/svg+xml;charset=UTF-8,@{arrow-left-on}');
  }
  .next:hover:not(.disable):after{
    background-image: url('data:image/svg+xml;charset=UTF-8,@{arrow-right-on}');
  }
  .pages{
    padding: 0;
    margin: 0;
    display: flex;
    justify-content: center;
    list-style: none;
  }
  .pages li{
    position: relative;
    .hoverUnderline(3px,@paging-icon-color);
    font-size: 1.5rem;
    margin: 0;
    font-weight: 700;
    letter-spacing: 0.2em;
    text-indent: 0.2em;
    span,a,i{
      display: flex;
      align-items: center;
      justify-content: center;
      .square(4.5em);
      transition: all @transition-fast ease-in-out;
      @media @xsmall {
        height: 2.8em;
        width: 2.8em;
      }
    }
    span{
      color: @paging-num-hover-color;
      background: @paging-num-hover-bg;
    }
    a{
      .transition(all @transition-fast ease-in-out);
      color: @paging-num-color;
      background: @paging-num-bg;
      &:hover{
        text-decoration: none;
        color: @paging-num-hover-color;
        background: @paging-num-hover-bg;
      }
    }
    i{
      color: @paging-num-color;
      letter-spacing: 0.02em;
      text-indent: 0.02em;
      padding-bottom: 0.5em;
    }
    &:after{
      left:50%;
      position: absolute;
      bottom:0;
    }
    &:hover:after,
    &[class*='current']:after,
    &[class*='active']:after{
      display: none;
    }
  }
  .backbtn {
    flex-grow: 1;
    width: 100%;
    padding: 0px;
    text-align: center;
    @media @small-up{
      max-width: 126px;
      margin: 0;
    }
    @media @xsmall {
      max-width: 80px;
    }
  }
  .backbtn a {
    display: flex;
    align-items: center;
    justify-content: center;
    white-space: nowrap;
    width: 100%;
    height: 100%;
    padding: 24px 15px;
    font-weight: 700;
    letter-spacing: 0.3em;
    text-indent: 0.3em;
    transition: all @transition-fast ease-in-out;
    color: @paging-backbtn-color;
    background: @paging-backbtn-bg;
    border: @paging-backbtn-border;
    border-width: 0px 1px;
    font-size: 0;
    color: transparent;
    text-indent: -9999px;
    &:after{
      content: '';
      display: block;
      width: 30px;
      height: 30px;
      margin: 0 auto;
      background: url('data:image/svg+xml;charset=UTF-8,@{doticon}') center / contain no-repeat;
    }
    &:hover{
      text-decoration: none;
      background: @paging-backbtn-hover-bg;
      color: @paging-backbtn-hover-color;
    }
  }
  .totalpages{
    padding-left: 10px;
    padding-right: 10px;
    text-align: center;
    font-size: 1.3rem;
    letter-spacing: 0.3em;
    text-indent: 0.3em;
    font-weight: 700;
  }
}