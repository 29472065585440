@keyframes scrollLine {
  0% {
    transform: translateY(0)
  }

  100% {
    transform: translateY(15px)
  }
}

// 1カラム化
.is--home {
  .hybs-one-column(100%);
}

.mainvisual {
  position: relative;
  padding-bottom: 80px;
  margin-bottom: 80px;
  z-index: 0;
  overflow: hidden;

  @media @xsmall {
    padding-bottom: 160px;
    margin-bottom: 0;
  }

  &:after {
    content: '';
    position: absolute;
    top: 50px;
    right: 0;
    bottom: 0;
    height: auto;
    width: 84vw;
    background:
      radial-gradient(circle at 80% 20%, #f6ef3a 5%, #39b14a 35%),
      radial-gradient(circle at 80% 90%, #1ba647 5%, #39b14a 35%);
    z-index: -1;

    @media @xsmall {
      width: 100%;
      background: radial-gradient(circle at 80% 20%, #f6ef3a 5%, #1ba647 70%);
    }
  }

  &__slider {
    max-width: 84vw;

    @media @xsmall {
      max-width: calc(100vw - 25px);
    }
  }

  &__slider-item {
    display: block !important;
  }

  &__catch {
    position: absolute;
    top: 0;
    left: 0;
    bottom: 80px;
    width: 91vw;
    display: flex;
    align-items: flex-end;
    justify-content: flex-end;
    padding-bottom: 4.3vw;
    pointer-events: none;

    @media @small-up {
      max-height: calc(100vh - 100px);
    }

    @media @xsmall {
      bottom: 66px;
      justify-content: center;
      width: 100%;
      padding: 0 25px;
    }

    img {
      display: block;
      max-width: 100%;
      margin: 0 auto;
      @media @small-up {
        max-width: 30vw;
      }
    }
  }

  &__scroll {
    display: flex;
    align-items: center;
    flex-direction: column;

    @media @small-up {
      position: absolute;
      left: 50%;
      transform: translateX(-50%);
      bottom: 0;
    }

    &:hover {
      text-decoration: none !important;
    }

    &__wrap {
      display: flex;
      justify-content: center;
      position: absolute;
      bottom: 0;
      left: 50%;
      transform: translateX(-50%);
      width: calc(100% - 15vw);
      max-width: 100%;
      margin: 0 auto;
      text-align: center;

      @media @xsmall {
        width: 100%;
      }
    }

    span {
      display: block;
      padding-bottom: 5px;
      font-family: 'Roboto', sans-serif;
      font-size: 1.1rem;
      font-weight: @medium-font-weight;
      color: @white;
      letter-spacing: .14rem;
    }

    .icon {
      fill: @white;

      use {
        animation: scrollLine 1.5s ease-in-out infinite alternate;
      }
    }
  }
}

.block-news {
  padding: 0 27px;
  margin-bottom: 80px;

  @media @xsmall {
    padding-top: 15px;
    padding-bottom: 15px;
    margin-bottom: 0;
  }

  &__inner {
    max-width: 960px;
    margin: 0 auto;

    @media @xsmall {
      display: flex;
      align-items: center;
    }
  }

  &__title {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    font-family: 'Play', @sans-serif-font-family;
    font-size: 2.6rem;
    letter-spacing: 0.2em;
    text-indent: 0.2em;
    font-weight: @bold-font-weight;
    color: #009245;

    small {
      font-size: 1.2rem;
      letter-spacing: 0.2em;
      text-indent: 0.2em;
      font-weight: inherit;
      color: @body-color;
    }

    @media @xsmall {
      margin: 0 22px 0 0;
      align-items: flex-start;
      font-size: 2rem;
      text-indent: 0;

      small {
        text-indent: inherit;
        font-size: 1.1rem;
      }
    }
  }

  &__list {
    display: flex;
    align-items: stretch;
    list-style: none;
    padding: 0;
    margin: 30px 0;

    @media @xsmall {
      margin: 0;
    }
  }

  &__btnwrap {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 40px;

    @media @xsmall {
      display: none;
    }

    .button {
      font-family: 'Roboto', @sans-serif-font-family;
      font-weight: @medium-font-weight;
    }
  }
}

.block-news-item {
  margin: 0;

  @media @small-up {
    border: solid #e4e4e4;
    border-width: 0 1px;
  }

  @media @small-up {
    width: 25%;
  }

  &:nth-child(n+2) {
    @media @xsmall {
      display: none;
    }
  }

  &+& {
    @media @small-up {
      border-left-width: 0;
    }
  }

  &__link {
    display: block;
    padding: 20px 22px;
    color: inherit;

    @media @xsmall {
      padding: 15px 0;
    }

    a&:hover,
    a&:focus {
      text-decoration: none;

      .subject {
        text-decoration: underline;
      }
    }
  }

  &__body {
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    font-feature-settings: "palt";

    .date {
      font-size: 1.1rem;
      color: #808080;
      font-family: 'Roboto', @sans-serif-font-family;
      letter-spacing: 0.12em;
      margin-right: 1.5em;
    }

    .cate {
      .truncate;
      flex-grow: 1;
      display: inline-block;
      width: 9em;
      max-width: 12em;
      border-radius: 10em;
      padding: 0.1em 0.8em;
      font-size: 1.2rem;
      letter-spacing: 0.1em;
      background-color: @secondary;
      text-align: center;
    }

    .subject {
      display: block;
      width: 100%;
      font-weight: @bold-font-weight;
      letter-spacing: 0.125em;
      line-height: 1.7;
      margin-top: 0.3em;

      @media @xsmall {
        font-size: 1.3rem;
      }
    }
  }
}

.block-facility {
  position: relative;
  background-color: #f6f6f6;
  padding: 60px 0 160px;
  z-index: 0;

  @media @xsmall {
    padding-left: 27px;
    padding-right: 27px;
    padding-bottom: 110px;
  }

  &__title {
    .c-block-title;
    margin-bottom: 1.2em;
  }

  &__bg{
    position: absolute;
    pointer-events: none;
    overflow: hidden;
    z-index: -1;
    @media @xsmall {
      svg{
        max-width: 100%;
        max-height: 100%;
      }
    }
  }

  @media @small-up {
    &__bg.is-bg-01{
      top: -140px;
      right: 0;
      max-width: 250px;
    }
    &__bg.is-bg-02{
      top: -170px;
      left: 50px;
    }
    &__bg.is-bg-03{
      top: 43%;
      left: calc(50% - 180px);
    }
    &__bg.is-bg-04{
      bottom: 15px;
      right: 0;
      max-width: 250px;
    }
    &__bg.is-bg-05{
      bottom: 0;
      left: -40px;
      max-height: 100px;
    }
  }
  @media @xsmall {
    &__bg.is-bg-01{
      top: 24%;
      right: 0;
      max-width: 80px;
      height: 106px;
      svg{
        max-width: 130%;
      }
    }
    &__bg.is-bg-02{
      top: -40px;
      left: -20px;
      width: 80px;
      height: 110px;
      .path1{
        display: none;
      }
    }
    &__bg.is-bg-03,
    &__bg.is-bg-04{
      display: none;
    }
    &__bg.is-bg-05{
      width: 100px;
      height: 80px;
      left: -25px;
      bottom: -25px;
    }
  }

  &__item {
    @media @small-up {
      position: relative;
      display: flex;
      align-items: center;
      margin-bottom: 100px;
      z-index: 0;
      overflow: hidden;
    }

    @media @xsmall {
      margin-bottom: 65px;
    }

    &:last-child {
      margin-bottom: 0;
    }
  }

  &__item:nth-child(even) {
    @media @small-up {
      flex-direction: row-reverse;
    }
  }


  &__item-img {
    @media @small-up {
      width: 50%;
      max-width: calc(50vw - 20px);

      &-wrap {
        height: 450px;
      }

      img {
        object-fit: cover;
        height: 100%;
      }
    }

    @media @xsmall {
      margin-bottom: 20px;
    }

    img {
      display: block;
      width: 100%;
    }
  }

  &__item:nth-child(odd) &__item-img {
    @media @small-up {
      margin-right: 70px;
    }
  }

  &__item:nth-child(even) &__item-img {
    @media @small-up {
      margin-left: 70px;
    }
  }

  &__item.is-tennis:after,
  &__item.is-rentalcourt:after {
    @media @small-up {
      position: absolute;
      content: '';
      bottom: 30px;
      font-family: 'Play', @sans-serif-font-family;
      font-size: 13.5rem;
      letter-spacing: 0.15em;
      font-weight: @bold-font-weight;
      color: fade(#c8c8c8, 30%);
      line-height: 0.85;
      z-index: -1;
      max-width: 4.5em;
    }
  }

  &__item.is-tennis:after {
    @media @small-up {
      content: 'AUTO\ATENNIS';
      left: calc(50% + 140px);
    }
    @media (max-width: 1475px){
      left: auto;
      right: 0;
    }
  }

  &__item.is-rentalcourt:after {
    @media @small-up {
      content: 'RENTAL\A COURT';
      right: calc(50% + 140px);
    }
    @media (max-width: 1475px){
      right: auto;
      left: 0;
    }
  }

  &__item-body {
    font-feature-settings: "palt";
    width: 100%;

    @media @small-up {
      padding: 0 20px;
      max-width: 500px;
    }

    .title {
      font-size: 3.1rem;
      letter-spacing: 0.2em;
      color: @primary;
      font-weight: @bold-font-weight;
      margin-bottom: 0.4em;

      @media @xsmall {
        font-size: 2.5rem;
      }

      &+p {
        margin-top: 0;
      }
    }

    p {
      line-height: 1.85;
      letter-spacing: 0.2em;
    }

    .button {
      width: 100%;
      max-width: calc(50% - 0.5em);
      font-weight: @bold-font-weight;
      font-family: 'Roboto', @sans-serif-font-family;
      margin-bottom: 1.5em;
      @media @xsmall {
        max-width: 70%;
      }
      &.is-xs-narrow{
        @media @xsmall {
          max-width: calc(50% - 0.5em);
        }
      }

      &:last-child {
        margin-bottom: 0;
      }

      &.is-reservation {
        max-width: 70%;

        .fa {
          margin-right: 0.5em;
        }
      }
    }
  }

  .btnwrap {
    margin-top: 3em;
    letter-spacing: 0.5em;

    @media @xsmall {
      margin-top: 1.5em;
      text-align: center;
    }
  }

}

.block-guide {
  position: relative;
  display: flex;
  align-items: stretch;
  z-index: 2;

  @media @xsmall {
    flex-direction: column;
  }

  &__link {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    padding: 27px;
    color: @white;
    font-size: 5.4rem;
    letter-spacing: 0.15em;
    text-indent: 0.15em;
    line-height: 1;
    font-family: 'Play', @sans-serif-font-family;
    text-align: center;
    font-feature-settings: "palt";
    overflow: hidden;
    z-index: 0;

    @media @small-up {
      height: 375px;
    }

    @media @xsmall {
      font-size: 3.4rem;
      height: 200px;
    }

    a&:hover,
    a&:focus {
      color: @white;
      text-decoration: none;
    }

    &:after {
      content: '';
      position: absolute;
      z-index: -1;
      top: 0;
      left: 0;
      bottom: 0;
      width: 100%;
      background: center / cover no-repeat;
      transition: all @transition-fast ease-in-out;
    }

    &:hover:after,
    &:focus:after {
      transform: scale(1.1);
    }

    @media @small-up {
      width: 50%;
    }

    strong {
      font-family: inherit;
      font-size: inherit;
    }

    span {
      margin-top: 0.4em;
      font-size: 1.8rem;
      letter-spacing: 0.2em;
      line-height: 1.5;
      font-weight: @bold-font-weight;

      @media @xsmall {
        font-size: 1.4rem;
      }
    }
  }

  &__link.is-process:after {
    background-image: url('/asset/img/home/process_bg@2x.jpg');
  }

  &__link.is-aboutus:after {
    background-image: url('/asset/img/home/aboutus_bg@2x.jpg');
  }
}

.block-sns {
  position: relative;
  margin: 70px 0 100px;
  z-index: 1;

  @media @xsmall {
    margin-top: 40px;
  }

  &__inner{
    @media @small-up {
      display: grid;
      grid-template-rows: repeat(3, auto);
      grid-template-columns: 1fr 1fr;
    }
  }

  &__area{
    padding: 0 25px;
    margin-bottom: 50px;
    @media @xsmall {
      margin-bottom: 35px;
    }
    img{
      display: block;
      max-width: 100%;
      margin: 0 auto;
    }
  }
  &__area.is-area01{
    @media @small-up {
      grid-row: 1;
      grid-column: 1 / span 2;
      justify-self: center;
    }
  }
  &__area.is-area02{
    @media @small-up {
      margin-top: 44px;
      grid-row: 2;
      grid-column: 1;
    }
  }
  &__area.is-area03{
    @media @small-up {
      max-width: 460px;
      grid-row: 2;
      grid-column: 2;
    }
  }
  &__area.is-area04{
    @media @small-up {
      grid-row: 3;
      grid-column: 1;
    }
  }
  &__area.is-area05{
    @media @small-up {
      grid-row: 3;
      grid-column: 2;
    }
  }
  &__area.is-area02,
  &__area.is-area04{
    @media @small-up {
      justify-self: end;
    }
  }

  &__area.is-area01,
  &__area.is-area02,
  &__area.is-area03,
  &__area.is-area04{
    @media @xsmall {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
    }
  }

  &__area.is-area05{
    @media @small-up {
      align-self: center;
    }
    @media @xsmall {
      text-align: center;
    }
  }

  &__title{
    font-weight: @bold-font-weight;
    font-size: 1.6rem;
    letter-spacing: 0.18em;
    color: @primary;
  }

  &__tw-title{
    text-align: center;
    font-size: 1.9rem;
    font-family: 'Roboto', @sans-serif-font-family;
    letter-spacing: 0.24em;
    .fa{
      letter-spacing: 0.1em;
    }
    a{
      font-size: 1.1rem;
      color: #808080;
      letter-spacing: 0.2em;
      margin-left: 1em;
    }
  }

  .twitter-timeline{
    border: solid rgba(15,70,100,.12) !important;
    border-width: 0 1px !important;
  }

  .sns-link{
    position: relative;
    display: inline-block;
    color: inherit;
    font-family: 'Roboto', @sans-serif-font-family;
    font-weight: @bold-font-weight;
    letter-spacing: 0.255em;
    padding-right: 1em;
    margin-right: 2em;
    font-size: 1.6rem;
    @media @xsmall {
      margin-right: 1em;
      margin-bottom: 0.5em;
      font-size: 1.5rem;
    }
    &:last-child{
      margin-right: 0;
    }
    .fa{
      margin-right: 0.3em;
    }
    .fa-lg{
      font-size: 1.75em;
    }
    &:after{
      position: absolute;
      top: 50%;
      right: 0.3em;
      content: '';
      height: 7px;
      width: 4px;
      transform: translateY(-50%);
      .make-svg-arrow(@body-color);
    }
  }

  &__bg{
    position: absolute;
    pointer-events: none;
    overflow: hidden;
    z-index: -1;
    @media @xsmall {
      display: none;
    }
  }
  @media @small-up {
    &__bg.is-bg-01{
      top: 0;
      right: 0;
      max-width: 163px;
    }
    &__bg.is-bg-02{
      top: -170px;
      left: -60px;
    }
    &__bg.is-bg-03{
      bottom: -140px;
      left: 30px;
    }
  }
}