/*
 * Title
 */

.c-block-title {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-family: 'Play', @sans-serif-font-family;
  font-size: 5.4rem;
  font-weight: @bold-font-weight;
  letter-spacing: 0.15em;
  text-indent: 0.15em;
  color: @lightgreen;

  @media @xsmall {
    font-size: 3.4rem;
  }

  small {
    color: @body-color;
    letter-spacing: 0.27em;
    text-indent: 0.27em;
    font-weight: @bold-font-weight;
    font-size: 1.7rem;

    @media @xsmall {
      font-size: 1.4rem;
    }
  }
}

.make-arc(@theme: @lightgreen) {
  @arc: escape('<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 50 7"><path fill="@{theme}" d="M48.51,7A1.47,1.47,0,0,1,48,6.9,66.81,66.81,0,0,0,2,6.9,1.52,1.52,0,0,1,.1,6.1,1.39,1.39,0,0,1,1,4.27a69.9,69.9,0,0,1,48.1,0A1.39,1.39,0,0,1,49.9,6.1,1.49,1.49,0,0,1,48.51,7Z"/></svg>');
  background: center / cover no-repeat;
  background-image: url('data:image/svg+xml;charset=UTF-8,@{arc}');
}

.c-title-underarc {
  position: relative;
  font-size: 3.5rem;
  text-align: center;
  letter-spacing: 0.16em;
  text-indent: 0.16em;
  font-weight: @bold-font-weight;
  font-family: 'Play', @sans-serif-font-family;
  padding-bottom: 0.1em;

  @media @xsmall {
    font-size: 2.7rem;
  }

  &:after {
    position: absolute;
    top: 100%;
    left: calc(50% - 25px);
    content: '';
    height: 7px;
    width: 50px;
    .make-arc;
  }
}