.antialias(@antialias:auto){
  -webkit-font-smoothing: @antialias;
  -moz-osx-font-smoothing: @antialias;
}

.hoverOpacity(@opacity:.8){
  .transition(opacity @transition-fast ease-in-out);
  &:hover{
    opacity: @opacity;
  }
}
.hoverUnderline(@border-width,@border-color){
  &:after{
    display: block;
    content:'';
    width: 0%;
    height: 0;
    border-bottom: @border-width solid @border-color;
    position: relative;
    margin: 0 auto;
    .transition(all @transition-fast ease-in-out);
  }
  &:hover{
    text-decoration: none;
  }
  &:hover:after,
  &:focus:after,
  &[class*="current"]:after,
  &[class*="active"]:after{
    width: 100%;
  }
}

.hybs-one-column(@container-width:100%,@gutter: 28px){
  #container{
    padding-left: @gutter;
    padding-right: @gutter;
    & when ( @container-width = 100% ) {
      padding-left: 0;
      padding-right: 0;
    }
    @media @small-up {
      width: 100%;
      max-width: @container-width + ( @gutter * 2 );
      & when ( @container-width = 100% ) {
        max-width: @container-width;
        width: @container-width;
      }
    }
    @media @xsmall {
      width: 100%;
    }
  }
  #main,#side{
    width: 100%;
    max-width: none;
  }
  #container,#main,#side{
    float: none;
    &:before,&:after,br[clear]{
      display: none;
    }
  }
  #side,
  .detailfooter .totalpages{
    display: none;
  }
  #container{
    &.main-right{
      #main,#side{ float: none;}
    }
  }
  @media @xsmall{
    .ui-content{
      padding-left: 0;
      padding-right: 0;
    }
    .element_freearea{
      margin-bottom: 0;
    }
    #main>.block .element_block,
    #main>.element_list,
    #side>.block .element_block{
      padding-left: 0;
      padding-right: 0;
    }
  }
}