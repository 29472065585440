/*
 * Button
 */

// Button
.button() {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;
	font-family: @button-font-family;
	font-weight: @button-font-weight;
	line-height: @button-line-height;
	letter-spacing: @button-letter-spacing;
	text-align: @button-text-align;
	text-transform: @button-text-transform;
	white-space: nowrap;
  vertical-align: middle;
	cursor: pointer;
	border-radius: @button-border-radius;
	.transition(all @transition-fast ease-in-out);
}

// Button Styles
.button-styles(@color, @background, @border) {
	color: @color;
	background: @background;
	border: @border;
}

// Button Theme
.button-theme(@color: @button-color, @background: @button-background, @border: @button-border, @hover-color: @button-hover-color, @hover-background: @button-hover-background, @hover-border: @button-hover-border) {
  &,&:visited{
    .button-styles(@color, @background, @border);
  }

  &:not(:disabled):hover,
  &:not(:disabled):focus {
    .button-styles(@hover-color, @hover-background, @hover-border);
    text-decoration: none;
  }
}

// Button Icon Theme
.button-icon-theme(@fill: @button-icon-fill, @hover-fill: @button-icon-hover-fill) {

	> svg {
		fill: @fill;
	}

	&:not(:disabled):hover,
	&:not(:disabled):focus {

		> svg {
			fill: @hover-fill;
		}
	}
}

// Button Size
.button-size(@padding, @height, @font-size) {
	padding: @padding;
	height: @height;
	.font-size(@font-size);
}