/* naotora jump */
@keyframes balljump {
  0% {
    .translateY(0);
  }

  10% {
    .translateY(-53px);
  }

  20% {
    .translateY(3px);
  }

  25% {
    .translateY(-10px);
  }

  30% {
    .translateY(0px);
  }

  35% {
    .translateY(-5px);
  }

  40% {
    .translateY(0px);
  }

  82% {
    .translateY(0px);
  }

  100% {
    .translateY(5px);
  }
}

/* naotora jump */
@keyframes ballrotate {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}

.p-footer-calendar {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #f6f6f6;
  padding: 90px 15px;
  z-index: 0;

  @media @xsmall {
    padding-top: 45px;
    padding-bottom: 130px;
  }

  &__inner {
    margin: 0;

    @media @small-up {
      display: inline-grid;
      grid-template-rows: auto auto;
      grid-template-columns: auto auto;
    }
  }

  &__head {
    @media @small-up {
      grid-row: 1;
      grid-column: 1;
      align-self: end;
    }
  }

  &__body {
    @media @small-up {
      grid-row: 1 / span 2;
      grid-column: 2;
      max-width: 593px;
      margin-left: 50px;
    }

    @media @xsmall {
      margin-bottom: 40px;
    }
  }

  &__contact {
    @media @small-up {
      grid-row: 2;
      grid-column: 1;
    }
  }

  &__bg{
    position: absolute;
    right: 25px;
    top: -100px;
    pointer-events: none;
    z-index: -1;
    @media @xsmall {
      top: -45px;
      right: 10px;
      width: 80px;
      height: 80px;
      svg{
        max-width: 100%;
        max-height: 100%;
      }
    }
  }
}

.p-footer-calendar {
  &__title {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    margin-bottom: 1.9em;
    text-align: center;
    font-size: 2.7rem;
    font-weight: @bold-font-weight;
    letter-spacing: 0.2em;

    @media @xsmall {
      font-size: 2.5rem;
    }

    small {
      margin-top: 0.3em;
      font-family: 'Play', @sans-serif-font-family;
      color: #85c634;
      font-weight: @bold-font-weight;
      font-size: 1.3rem;
      letter-spacing: 0.175em;
      text-indent: 0.175em;
    }
  }

  &__contact {
    b {
      display: block;
      text-align: center;
      font-size: 1.7rem;
      letter-spacing: 0.175em;
      text-indent: 0.175em;

      @media @xsmall {
        font-size: 1.6rem;
      }
    }

    .tel {
      margin-top: 0;
      font-size: 1.8rem;
      font-family: 'Roboto', @sans-serif-font-family;
      font-weight: @medium-font-weight;
      font-feature-settings: "palt";
      text-align: center;

      span:first-child {
        margin-right: 0.2em;
      }

      a {
        font-size: 3.4rem;
        color: @primary;
        letter-spacing: 0.08em;
      }

      span.opentime {
        display: block;
        padding-left: 5em;
        font-size: 1.2rem;
        letter-spacing: 0.2em;
        text-align: left;

        @media @xsmall {
          text-align: center;
          padding-left: 0;
        }

      }
    }
  }

  &__btnwrap {
    display: flex;
    align-items: center;
    justify-content: center;
  }
}

footer {
  background-color: @body-color;
  color: @white;
}

.p-pagetop {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  position: absolute;
  right: 20px;
  bottom: -35px;
  width: 87px;
  height: 66px;
  font-size: 1.3rem;
  font-family: 'Roboto', sans-serif;
  color: @white;
  text-decoration: none;
  letter-spacing: 0.12em;
  text-indent: 0.12em;

  @media @xsmall {
    right: calc(50% - 43px);
  }

  span {
    position: absolute;
    bottom: 100%;
    background: @body-color;
    color: @white;
    font-family: 'Play', @sans-serif-font-family;
    left: 0;
    width: 100%;
    text-align: center;
    padding: 0.3em 0.2em;
    margin-bottom: 10px;
    z-index: -1;
    transition: all 0.2s linear;

    &:after {
      .arrow-bottom(5px, @body-color);
    }
  }

  &:hover {
    color: @white  !important;
    text-decoration: none !important;

    img {
      animation: balljump 1.7s infinite ease-in-out;
    }

  }

  &.is-active img {
    animation: ballrotate 1s infinite ease-in-out;
  }

  &.is-active span {
    opacity: 0;
  }

  &__wrap {
    width: 100%;
    position: fixed;
    position: sticky;
    bottom: 55px;
    z-index: 10;
    .transition(all 0.6s ease-out);

    &.hidden {
      @media @small-up {
        .translateY(-100vh);
      }

      opacity: 0;
      pointer-events: none;
    }
  }
}

.p-footer-contact {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 40px 25px;
  background-color: #135d5d;
  color: @white;

  @media @xsmall {
    flex-direction: column;
    padding-top: 55px;
    padding-bottom: 50px;
  }

  &__head {
    @media @small-up {
      margin-right: 50px;
    }

    @media @xsmall {
      margin-bottom: 30px;
    }
  }

  &__body {}

  &__title {
    position: relative;
    padding-bottom: 0.3em;
    margin-bottom: 0.65em;
    font-family: 'Play', @sans-serif-font-family;
    font-weight: @regular-font-weight;
    color: inherit;
    font-size: 2.7rem;
    letter-spacing: 0.075em;

    @media @xsmall {
      margin-bottom: 0.3em;
      padding-bottom: 0;
      text-align: center;
    }

    &:after {
      position: absolute;
      top: 100%;
      left: 0;
      height: 1px;
      width: 1.3em;
      content: '';
      background-color: @white;

      @media @xsmall {
        display: none;
      }
    }
  }

  &__lead {
    font-feature-settings: "palt";
    font-size: 1.3rem;
    letter-spacing: 0.16em;

    @media @xsmall {
      display: block;
      text-align: center;
    }
  }

  &__btnwrap {}

  .tel {
    margin-top: 0;
    font-size: 1.8rem;
    font-family: 'Roboto', @sans-serif-font-family;
    font-weight: @medium-font-weight;
    font-feature-settings: "palt";
    color: inherit;

    span:first-child {
      margin-right: 0.2em;
    }

    a {
      font-size: 3.4rem;
      color: @white;
      letter-spacing: 0.08em;
    }

    span.opentime {
      display: block;
      padding-left: 3.7em;
      font-size: 1.2rem;
      letter-spacing: 0.2em;
      text-align: left;

    }
  }

  &__lineBtn {
    height: auto;
    font-size: 1.6rem;
    color: #06c755;
    font-weight: @bold-font-weight;
    border-width: 0;
    justify-content: flex-start;

    @media @small-up {
      width: 345px;
      padding-left: 2.5em;
    }

    p {
      margin: 0 0 0 1.3em;
      font-size: inherit;
      text-align: left;
      color: inherit;
      font-weight: inherit;
    }

    p span,
    p small {
      display: block;
    }

    &.c-btn-icon-right::after {
      .make-svg-arrow(#06c755);
    }

    &:not(:disabled):hover,
    &:not(:disabled):focus {
      background-color: tint(#06c755, 80%);
      color: #06c755;
      border-width: 0;
    }
  }
}

.p-footer {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 5rem 3rem 0;

  @media @xsmall {
    padding-top: 3.5rem;
    padding-bottom: 3.5rem;
  }

  &__inner {
    width: 100%;
    max-width: 900px;
    margin: 0 auto;
    display: flex;

    @media @small-up {
      justify-content: flex-end;
      text-align: left;
    }

    @media @xsmall {
      flex-direction: column;
    }
  }

  &__head {
    padding-bottom: 10px;

    @media @small-up {
      border-right: 1px solid #808080;
      padding-right: 45px;
    }

    @media @xsmall {
      padding-bottom: 35px;
      border-bottom: 1px solid #626262;
    }

    .address {
      font-style: normal;
      display: block;
      margin: 1em 0;
      font-size: 1.2rem;
      letter-spacing: 0.2em;
      font-feature-settings: "palt";
    }

    .button-border-white {
      border-color: #626262;
    }

    .button-small {
      font-size: 1.3rem;
      height: auto;
      letter-spacing: 0.15em;

      .fa {
        margin-right: 0.5em;
      }
    }
  }

  &__body {
    @media @small-up {
      display: flex;
      flex-direction: column;
      justify-content: center;
      width: 50%;
      padding-left: 45px;
    }

    @media @xsmall {
      padding-top: 35px;
    }

  }

  &__logo {
    .hoverOpacity;
    display: block;
    margin-bottom: 1em;
  }

  &__social {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    margin-bottom: 1em;

    @media @xsmall {
      justify-content: center;
    }

    b {
      font-size: 1.4rem;
      letter-spacing: 0.15em;
      font-family: 'Roboto', @sans-serif-font-family;
      margin-right: 3em;

      @media @xsmall {
        font-size: 1.3rem;
      }
    }

    a {
      color: inherit;
      font-size: 2.8rem;
      margin-right: 0.5em;

      &.is-tw:hover,
      &.is-tw:focus {
        color: @sns-twitter-bg;
      }

      &.is-ig:hover,
      &.is-ig:focus {
        color: @sns-insta-bg;
      }

      &.is-fb:hover,
      &.is-fb:focus {
        color: @sns-facebook-bg;
      }
    }
  }

  &__nav {
    margin-bottom: 0em;

    @media @small-up {
      margin-left: -1em;
    }

    a.is-subnav {
      color: fade(@white, 70%);
    }

    &:last-child {
      margin-bottom: 0;
    }

    a {
      position: relative;
      display: inline-block;
      margin: 0 0.8em;
      color: inherit;
      font-size: 13px;
      line-height: 2.5;
      vertical-align: middle;

      &:after {
        position: absolute;
        top: calc(50% - 0.5em);
        left: calc(100% + 1em);
        content: '';
        height: 1em;
        width: 1px;
        background-color: #808080;
      }

      &:last-child:after {
        display: none;
      }

      &:hover,
      &:focus {
        text-decoration: none;
        color: shade(@white, 20%);
      }
    }
  }

  &__copyright {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 1em 3rem;
    height: 90px;
    width: calc(100% + 6rem);
    margin: 3rem -3rem 0;
    color: @white;

    .promotion,
    .copyright,
    .login {

      a,
      a:visited {
        color: fade(@white, 70%);
      }
    }

    .promotion {
      margin-bottom: 0;
    }

    .copyright {
      margin-bottom: 0.5em;
    }

    .login {
      margin-top: 1em;
    }
  }
}