// テンプレートURL
@template-directory: '//secure.websapo.jp/_site_template/layout/rwd_001';

//import turret override
@import 'turret/turret';

//import components
@import '_components';

//import extend mixins
@import (reference) '_mixins_extend';

//webfont読み込み
@fontfile_path: '../fonts/';
@import 'fonts/font-awesome';
@import 'fonts/Roboto';
@import 'fonts/Play';
@import url('https://fonts.googleapis.com/css2?family=Noto+Serif+JP:wght@400;700&display=swap');
// @import url('https://fonts.googleapis.com/css?family=Montserrat:400,700');

// デフォルトをカスタム用に上書きするスタイル(基本変更しない)
@import '_common';

// CMSベーシックパーツインポート：サイト特有のカスタムも以下ファイル内に記載する
@import 'elements/toolbar';
@import 'elements/breadcrumbs';
@import 'elements/paginations';
@import 'elements/block';

// 各セクションパーツインポート：サイト特有のカスタムも以下ファイル内に記載する
@import 'elements/header';
@import 'elements/side';
@import 'elements/footer';

@import (inline) '@fullcalendar/core/main.css';
@import (inline) '@fullcalendar/daygrid/main.css';
@import (inline) '@fullcalendar/timegrid/main.css';
@import 'elements/calendar';

// サイト特有の全体用カスタマイズを以下に記載
@import '../node_modules/slick-carousel/slick/slick.less';
@import '../node_modules/slick-carousel/slick/slick-theme.less';
@import './components/svganimation.less';

#allbox .toolbar{
  display: none;
}
html.is-drawer-active{
  @media @xsmall {
    overflow: visible;
  }
}

.is--news {
  &.is--detail .element_detail {
    @media @xsmall {
      padding: 0 28px;
    }
    .entry img{
      max-width: 100%;
    }
  }
}
// sitemap
#main>.element.sitemap .cat>li h4.catetitle a:hover{
  color: @lightgreen;
  background-color: transparent;
}


// ページごとのスタイルを読み込み
@import 'page/home.less';
@import 'page/aboutus.less';
@import 'page/facility.less';
@import 'page/home.less';
@import 'page/process.less';