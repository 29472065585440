// ツールバー設定
@toolbar-bg: @light-grey;
@toolbar-button-bg: #ccc;
@toolbar-button-color: @body-color;
@toolbar-button-current-bg: #666;
@toolbar-button-current-color: @white;
@toolbar-button-current-border: 1px solid #666;
@toolbar-button-border: 1px solid #ccc;
@toolbar-color: @body-color;

#allbox > .toolbar{
  width: 100%;
  padding: 6px 0;
  margin: 0;
  background: @toolbar-bg;
  .row{
    .container;
    margin: 0 auto;
    @media @medium-up{
      max-width: 100%;
    }
  }
  h1,label{
    color: @toolbar-color;
  }
  .fontChange{
    .button{
      background: @toolbar-button-bg;
      border: @toolbar-button-border;
      color: @toolbar-button-color;
      &.current{
        background: @toolbar-button-current-bg;
        color: @toolbar-button-current-color;
        border: @toolbar-button-current-border;
      }
    }
  }
  .hybsLogin a{
    background: url('@{template-directory}/asset/images/hybs_login.png') no-repeat 7px;
  }
  .hybsLogin a:hover,
  .hybsLogin a:active,
  .hybsLogin a:focus {
    background: url('@{template-directory}/asset/images/hybs_login.png') no-repeat -22px center;
    background-color: #c4c8d2;
  }
}