
@font-face {
    font-family: 'Play';
    src: url('@{fontfile_path}Play/Play-Regular.eot');
    src: url('@{fontfile_path}Play/Play-Regular.eot?#iefix') format('embedded-opentype'),
         url('@{fontfile_path}Play/Play-Regular.woff2') format('woff2'),
         url('@{fontfile_path}Play/Play-Regular.woff') format('woff'),
         url('@{fontfile_path}Play/Play-Regular.ttf') format('truetype');
    font-weight: 400;
    font-style: normal;
}

@font-face {
    font-family: 'Play';
    src: url('@{fontfile_path}Play/Play-Bold.woff2') format('woff2'),
         url('@{fontfile_path}Play/Play-Bold.woff') format('woff'),
         url('@{fontfile_path}Play/Play-Bold.ttf') format('truetype');
    font-weight: 700;
    font-style: normal;
}