/*
 * Button
 */


// Button Grey
@button-grey-color: @white;
@button-grey-background: @grey;
@button-grey-border: none;
@button-grey-hover-color: @button-grey-color;
@button-grey-hover-background: darken(@button-grey-background, 10%);
@button-grey-hover-border: none;

// Button Border white
@button-border-white-color: @white;
@button-border-white-background: transparent;
@button-border-white-border: 1px solid @button-border-white-color;
@button-border-white-hover-color: @body-color;
@button-border-white-hover-background: @white;
@button-border-white-hover-border: @button-border-white-border;


// Button Border white
.button-border-white,
a.button-border-white {
  .button-theme(@button-border-white-color,
    @button-border-white-background,
    @button-border-white-border,
    @button-border-white-hover-color,
    @button-border-white-hover-background,
    @button-border-white-hover-border );
}

// Button grey
a.button-grey,
.button-grey {
  .button-theme(@button-grey-color,
    @button-grey-background,
    @button-grey-border,
    @button-grey-hover-color,
    @button-grey-hover-background,
    @button-grey-hover-border );
}

.button-primary,
a.button-primary {
  .button-theme(@white, // color
    @primary, // background
    none, // border
    @white, // hover-color
    darken(@primary, 10%), // hover-background
    none // hover-border
  );
}

.button-border-primary,
a.button-border-primary {
  .button-theme(@primary, // color
    transparent, // background
    1px solid @primary, // border
    @white, // hover-color
    @primary, // hover-background
    1px solid @primary // hover-border
  );
}

.button-secondary,
a.button-secondary {
  .button-theme(@white, // color
    @secondary, // background
    none, // border
    @white, // hover-color
    darken(@secondary, 15%), // hover-background
    none // hover-border
  );
}

.button-lightgreen,
a.button-lightgreen {
  .button-theme(@white, // color
    @lightgreen, // background
    none, // border
    @white, // hover-color
    darken(@lightgreen, 10%), // hover-background
    none // hover-border
  );
}

.button-pill {
  border-radius: 100em;
}

.make-svg-arrow( @theme: @white ){
  @arrow: escape('<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 4.12 6.73"><path style="fill:none;stroke:@{theme};stroke-linecap:round;stroke-linejoin:round;" d="M.5.5,3.62,3.36.5,6.23"/></svg>');
  background: center / cover no-repeat;
  background-image: url('data:image/svg+xml;charset=UTF-8,@{arrow}');
}
.make-svg-arrow-down( @theme: @white ){
  @arrow: escape('<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 6.73 4.12"><path style="fill:none;stroke:@{theme};stroke-linecap:round;stroke-linejoin:round;" d="M6.23.5,3.36,3.62.5.5"/></svg>');
  background: center / cover no-repeat;
  background-image: url('data:image/svg+xml;charset=UTF-8,@{arrow}');
}

.c-btn-icon-right {
  position: relative;
  padding-left: 3em;
  padding-right: 3em;

  &:after {
    position: absolute;
    top: 50%;
    right: 1.5em;
    content: '';
    width: 4px;
    height: 7px;
    transform: translateY(-50%);
    .make-svg-arrow;
  }

  &.button-border-primary{
    &:after{
      .make-svg-arrow(@primary);
    }
    &:hover:after,&:focus:after{
      .make-svg-arrow(@white);
    }
  }
}
.c-btn-icon-right-down {
  .c-btn-icon-right;
  &:after {
    width: 7px;
    height: 4px;
    .make-svg-arrow-down;
  }

  &.button-border-primary{
    &:after{
      .make-svg-arrow-down(@primary);
    }
    &:hover:after,&:focus:after{
      .make-svg-arrow-down(@white);
    }
  }
}

.c-btn-curtain {
  position: relative;
  z-index: 0;
  overflow: hidden;

  &::before {
    content: '';
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    width: 0;
    background-color: @primary;
    z-index: -1;
    transition: all @transition-slow ease-in-out;
  }

  &:hover::before,
  &:focus::before {
    @media @small-up {
      width: 100%;
    }
  }

  &.button-border-primary{
    &:not(:disabled):hover,&:not(:disabled):focus{
      background-color: transparent;
    }
  }
}

.c-btn-curtain-lightgreen{
  .c-btn-curtain;
  &::before{
    background-color: @lightgreen;
  }
}