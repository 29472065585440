.is--aboutus {
  // 1カラム化
  .hybs-one-column(100%);

  .breadcrumbs,
  .detailfooter {
    display: none;
  }

  #main>.element_detail {
    margin-bottom: 0;
  }

}

.about-outline {
  padding: 0 27px;
  margin-bottom: 80px;

  @media @xsmall {
    margin-bottom: 50px;
  }

  &__title {
    .c-title-underarc;
    margin-bottom: 1.5em;
  }

  &__img {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: 70px;

    @media @xsmall {
      margin-bottom: 20px;
    }

    img {
      display: block;
      max-width: 100%;
      margin: 0;
    }
  }

  &__table {
    border-width: 0;
    max-width: 850px;
    margin: 0 auto;
    font-feature-settings: "palt";

    th,
    td {
      border-width: 0;
      background-color: transparent;
      letter-spacing: 0.15em;

      @media @small-up {
        padding: 1em;
      }
    }

    th {
      font-weight: @bold-font-weight;
      white-space: nowrap;
    }

    tr {
      border: dashed #b9b9b9;
      border-width: 0 0 1px;
    }

    tr:last-child {
      border-bottom-width: 0;
    }
  }
}

.about-access {
  padding: 80px 27px;
  background-color: #f6f6f6;

  @media @xsmall {
    padding-top: 50px;
    padding-bottom: 50px;
  }

  &__title {
    .c-title-underarc;
    margin-bottom: 1.5em;
  }

  &__map {
    max-width: 960px;
    margin: 0 auto;

    @media @small-up {
      display: grid;
      grid-template-rows: repeat(2, auto);
      grid-template-columns: 1fr auto;
    }
  }

  &__map-inner {
    position: relative;

    @media @small-up {
      height: 422px;
      grid-row: 1;
      grid-column: 1 / span 2;
      overflow: hidden;
    }

    iframe {
      display: block;

      @media @small-up {
        height: calc(100% + 400px);
        position: absolute;
        top: -200px;
        bottom: -200px;
      }
    }
  }

  &__map-btnwrap {
    margin-top: 1.5em;

    @media @xsmall {
      display: flex;
      justify-content: flex-end;
    }

    @media @small-up {
      grid-row: 2;
      grid-column: 2;
    }

    .button-border {
      background-color: @white;
    }
  }

  &__map-way {
    line-height: 1.75;
    letter-spacing: 0.075em;

    @media @small-up {
      grid-row: 2;
      grid-column: 1;
    }
  }
}

.about-shop {
  padding: 80px 27px;

  @media @xsmall {
    padding-top: 50px;
    padding-bottom: 50px;
  }

  &__title {
    .c-title-underarc;
    margin-bottom: 1.5em;
  }

  &__row {
    display: flex;
    align-items: center;
    justify-content: center;

    @media @xsmall {
      flex-direction: column;
      align-items: stretch;
    }
  }

  &__img {
    flex-shrink: 0;

    @media @small-up {
      margin-right: 50px;
    }

    @media @xsmall {
      margin-bottom: 30px;
    }

    img {
      display: block;
      max-width: 100%;
      margin: 0 auto;
    }
  }

  &__body {
    font-feature-settings: "palt";
    max-width: 450px;

    strong {
      display: block;
      margin-bottom: 0.5em;
      color: @primary;
      font-size: 1.7rem;
      letter-spacing: 0.15em;

      @media @xsmall {
        font-size: 1.6rem;
      }
    }
  }

  .title {
    font-size: 2rem;
    font-weight: @bold-font-weight;
    letter-spacing: 0.15em;
    line-height: 1.7;

    @media @small-up {
      margin-right: -0.5em;
    }

    @media @xsmall {
      font-size: 1.6rem;
    }
  }
}

.about-pet {
  padding: 80px 27px;
  background-color: #e7f5c9;

  @media @xsmall {
    padding-top: 50px;
    padding-bottom: 50px;
  }

  &__title {
    .c-title-underarc;
    margin-bottom: 1.5em;
  }

  &__row {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: row-reverse;

    @media @xsmall {
      flex-direction: column;
      align-items: stretch;
    }
  }

  &__img {
    position: relative;
    flex-shrink: 0;

    @media @small-up {
      margin-left: 50px;
    }

    @media @xsmall {
      margin-bottom: 30px;
      align-self: center;
    }

    img {
      display: block;
      max-width: 100%;
      margin: 0 auto;
    }

    .icon {
      position: absolute;
      top: 0;
      right: 0;
      transform: translate(17%, -20%);

      @media @xsmall {
        width: 130px;
      }
    }
  }

  &__body {
    font-feature-settings: "palt";
    max-width: 450px;

    strong {
      display: block;
      margin-bottom: 0.5em;
      color: @primary;
      font-size: 1.7rem;
      letter-spacing: 0.15em;

      @media @xsmall {
        font-size: 1.6rem;
      }
    }
  }

  .title {
    font-size: 2rem;
    font-weight: @bold-font-weight;
    letter-spacing: 0.15em;
    line-height: 1.7;

    @media @xsmall {
      font-size: 1.7rem;
    }
  }
}

.about-listBlock {
  list-style: none;
  font-feature-settings: "palt";
  margin-top: 0;

  li {
    margin: 0 0 0.6em;
    letter-spacing: 0.12em;
    padding-left: 1.3em;
    text-indent: -1.3em;

    &:before {
      content: '\f10c';
      font-family: FontAwesome;
      color: @lightgreen;
      display: inline-block;
      width: 1.3em;
      text-indent: 0;
    }

    &:last-child {
      margin-bottom: 0;
    }

    span {
      font-weight: @regular-font-weight;
    }
  }
}